<div class="d-flex flex-column-reverse">
    <div
        *ngFor="let alert of alerts"
        class="{{ cssClass(alert) }} alert-dismissable row justify-content-md-start align-items-center">
        <div
            class="col align-center alert-text-area">
            {{ alert.message }}
        </div>

        <a
            class="close"
            (click)="removeAlert(alert)">&times;</a>
    </div>
</div>
