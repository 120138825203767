<div class="container-fluid p-0 not-found-area">
    <div class="row not-found-content">
        <div class="col-12">
            <h1>404</h1>

            <h2>Oops, the page you're looking for does not exist.</h2>

            <p>
                You may want to head back to the homepage.
            </p>

            <a routerLink="/" class="btn btn-success">RETURN HOME</a>
        </div>
    </div>
</div>
