import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AfghanistanComponent } from './afghanistan.component';
import { AgricultureComponent } from './components/agriculture/agriculture.component';
import { CultureTraditionComponent } from './components/culture-tradition/culture-tradition.component';
import { HistoricPlacesComponent } from './components/historic-places/historic-places.component';
import { HistoryComponent } from './components/history/history.component';
import { NationalHotelsComponent } from './components/national-hotels/national-hotels.component';
import { NationalMuseumsComponent } from './components/national-museums/national-museums.component';
import { NationalParksComponent } from './components/national-parks/national-parks.component';
import { NationalZooComponent } from './components/national-zoo/national-zoo.component';
import { WaterDemsCanalsComponent } from './components/water-dems-canals/water-dems-canals.component';

const routes: Routes = [
  {
     path: '',
     component: AfghanistanComponent
  },
  {
    path:'agriculture',
    component: AgricultureComponent
  },
  {
    path: 'culture-tradition',
    component: CultureTraditionComponent
  },
  {
    path: 'historic-places',
    component: HistoricPlacesComponent
  },
  {
    path: 'history',
    component: HistoryComponent
  },
  {
    path: 'national-hotels',
    component: NationalHotelsComponent
  },
  {
    path: 'national-museums',
    component: NationalMuseumsComponent
  },
  {
    path: 'national-parks',
    component: NationalParksComponent
  },
  {
    path: 'national-zoo',
    component: NationalZooComponent
  },
  {
    path: 'water-dems-canals',
    component: WaterDemsCanalsComponent
  }
    ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AfghanistanRoutingModule { }
