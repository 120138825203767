<div class="card">
  <div class="card-header primary-bg">
    <h3 class="text-center" style="color: white;">Date Converter</h3>
  </div>
  <div class="card-body">

    <form [formGroup]="dateConverterForm" class="form">
      <div class="row"  >
        <div class="col-md-12">
          <label class="form-label">Gregorian Date</label>
          <div class="input-group input-group-outline my-3">
            <input type="date" class="form-control date" formControlName="gregorian" >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5 class="text-center">To</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="form-label">Shamsi Date</label>
          <div class="input-group input-group-outline my-4">
            <input type="date" class="form-control date" formControlName="shamsi">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 pt-0" >
          <button class="btn primary-bg text-white w-100 mt-2 mb-0" style=" background-image: linear-gradient(160deg,  #270F02,#f1c073) !important;" (click)="convert()">Convert</button>
        </div>
        <div class="col-md-1"></div>
      </div>
    </form>
  </div>
  <div class="card-footer" *ngIf="show">
    <div class="text-center">
      <p>Gregorian Date is : {{gregorianDate}}</p>
      <p>Shami Date is : {{persianDate}}</p>
    </div>
  </div>
</div>
