import { Component, OnInit, Input, Output } from '@angular/core';
import { BannerServiceService } from '../banner-service.service';
import { AboutUsService } from './service/about-us.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  public bannerData: any = [];
  public aboutUsData: any=[];

  public teamsData: any=[];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Input() notShow: boolean  = false;
  @Input() limit: number = 100;

  constructor(private service: AboutUsService, private bannerService: BannerServiceService) { }

  ngOnInit(): void {
    if(this.show) {
      this.showBanner = false;
    }
    this.bannerService.getBanners('AboutUs')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })
      this.getAboutUs();
      this.getTeams();
  }

  public getAboutUs(){
    this.service.getAboutUs()
    .subscribe({
      next: (response : any) => {
        this.aboutUsData = response?.about_us?.results;
      },
      error: (err) => {
      },

    })

  }


  public getTeams(){
    this.service.getTeams()
    .subscribe({
      next: (response : any) => {
        if(this.limit == 100){
          this.teamsData = response?.Teams?.results;
        }else if(this.limit != 100) {
          for(let i =1; i <=4; i++) {
            this.teamsData.push(response?.Teams?.results[i]);

          }
        }
      },
      error: (err) => {

      },

    })

  }
  toTop() {
    window.scrollTo(0, 0)
  }
}

