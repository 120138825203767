import { Component, OnInit, Input, Output} from '@angular/core';
import { AfghanistanService } from '../../services/afghanistan.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  // History Data
  public historyData: any = [];
  public bannerData: any = [];
  @Input() show: boolean  = true;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  public searchText;

  constructor(
    private AfghanistanService:AfghanistanService,
    private bannerService:BannerServiceService,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {
    this.bannerService.getBanners('Afg_History')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
          // image, title, component
        }
      })
    this.getHistory();
  }


  public getHistory() {
    this.AfghanistanService.getHistory()
      .subscribe({
        next: (response : any) => {
          this.historyData = response?.history?.results;
          this.nextLink = response?.history.next;

        }

      })
  }

  search() {
    this.commonService.search(this.searchText, 'afghanistan', 'history')
    .subscribe({
      next: (result) => {
        this.historyData = result['history']?.results;
      }
    })
}


public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.historyData = response?.history?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.history?.previous;
            this.nextLink = response?.history?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.history?.next;
              this.previousLink = response?.history?.previous;
              this.historyData = response?.history?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}
  toTop() {
    window.scrollTo(0, 0)
  }
}
