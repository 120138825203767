<div class="card">
  <div class="card-header " style="background-image: linear-gradient(160deg,  #270F02,#f1c073) !important;">
    <h3 class="text-center" style="color: white;">Currency Exchange</h3>
  </div>
  <div class="card-body">
    <form class="conversion-area row" [formGroup]="currencyForm">

      <div class="row">
        <div class="col-md-12">
          <div class="input-group input-group-outline my-3">
            <input type="number" class="form-control" formControlName="amountControl" placeholder="Amount">
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-12">
          <div class=" my-3">
            <!-- <label for="form-label">From</label> -->
            <ng-select style="width: 100%;" class="col-md-6" [items]="currencyFromData" bindLabel="displayText"
              bindValue="key" placeholder="From" [(ngModel)]="selectedCurrencyFrom" formControlName="fromControl">
              <ng-template ng-label-tmp let-item="item">
                {{ item.key }} - {{ item.value }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                {{ item.key }} - {{ item.value }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <button disabled style="background-image: linear-gradient(160deg,  #270F02,#f1c073) !important;" class="btn primary-bg text-white w-100 mt-2 mb-1
         icon-compare-arrows
         cursor--pointer primary--color
         lg-left-right opposite-arrows" (click)="changeExchangeInputValues()">
          </button>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <div class="my-3">
            <!-- <label for="form-label">From</label> -->
            <ng-select style="width: 100%;" class="col-md-6" [items]="currencyFromData" bindLabel="displayText"
              bindValue="key" placeholder="To" [(ngModel)]="selectedCurrencyTo" formControlName="toControl">
              <ng-template ng-label-tmp let-item="item">
                {{ item.key }} - {{ item.value }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                {{ item.key }} - {{ item.value }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <button (click)="convertCurrency()" style="background-image: linear-gradient(160deg,  #270F02,#f1c073) !important;"
            class="btn primary-bg text-white w-100 mt-1 mb-0">{{'Convert'}}
          </button>
        </div>
      </div>
    </form>

    <article class="text-center center" *ngIf="result">
      <section class="conversion-result-area justify-content-center mt-1 row">
        <p class="title--font-size">{{ amount }} {{ fromCurrency }}</p>
        <p class="ml-3 mr-3">=</p>
        <p class="title--font-size font--roboto-bold accent--color"
          style="background-image: linear-gradient(160deg,  #270F02,#f1c073) !important;">{{ result | number:'1.5-5' }}
          {{ toCurrency }}</p>
      </section>

      <!-- <section class="conversion-info-area justify-content-center row">
        <p class="body-text--font-size no--margin">
            1 {{ fromCurrency }}
            =
            {{ (+toRate / +fromRate) | number:'1.5-5' }} {{ toCurrency }}
        </p>

        <p class="w-100 no--margin"></p>

        <p class="body-text--font-size no--margin">1 {{ toCurrency }} = {{ (+fromRate / +toRate) | number:'1.5-5' }} {{ fromCurrency }}</p>
    </section> -->
    </article>
  </div>
</div>
