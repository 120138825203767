
<div class="container-fixed position-sticky z-index-sticky top-0 bg-primary" >
  <div class="row">
    <div class="col-12">
      <nav class="navbar navbar-expand-sm  blur  top-0 z-index-fixed shadow position-absolute my-0 py-2 start-8 end-8 mx-0">
        <div class="container-fluid px-0" >
          <a class="navbar-brand font-weight-bolder ms-sm-3"
            rel="tooltip" title="Designed and Coded by AZ-Dev Team" [routerLink]="['']" data-placement="bottom"
            style="margin-left: 50px; font-size: 18px;">
              <img [src]="bannerData?.image"  alt="" width="200" height="150" style="position: absolute; top: 1%; left: 4%; margin-top: -18px; z-index: 1;">
          </a>
          <button class="mobile-menu-toggle" (click)="toggleMobileMenu()">
            <i class="material-icons">menu</i>
          </button>
          <div class="collapse navbar-collapse pt-3 pb-2 py-lg-0 w-100  " [ngClass]="{'show': isMobileMenuOpen}" id="navigation">
            <ul class="navbar-nav navbar-nav-hover ms-auto " >
              <li class="nav-item dropdown dropdown-hover mx-1">
                <a class="nav-link ps-2 d-flex cursor-pointer align-items-center menu-items" id="dropdownMenuPages"
                  data-bs-toggle="dropdown" aria-expanded="true" [routerLink]="['']" routerLinkActive="router-link-active">
                  <i class="material-icons opacity-6 me-1 text-md "></i>
                  Home
                </a>
              </li>
              <li class="nav-item dropdown showAfghanistan dropdown-hover mx-1" >
                <a class="nav-link ps-2 d-flex cursor-pointer align-items-center menu-items" (click)="changeAfghanistan('open')"  id="dropdownMenuPages"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="material-icons opacity-6 me-2 text-md">tour</i>
                  Afghanistan
                  <img src="./assets/img/down-arrow-dark.svg" alt="down-arrow" class="arrow ms-auto ms-md-2">
                </a>
                <div class="dropdown-menu dropdown-menu-animation ms-n3 dropdown-md p-3 border-radius-xl mt-0 mt-lg-3"
                  aria-labelledby="dropdownMenuPages" #showAfghanistan>

                  <div>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/history']" (click)="changeAfghanistan('close')">
                      <span>History</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/culture-tradition']" (click)="changeAfghanistan('close')">
                      <span>Culture & Tradition</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/historic-places']" (click)="changeAfghanistan('close')">
                      <span>Historic Places</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/national-parks']" (click)="changeAfghanistan('close')">
                      <span>National Parks</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/national-museums']" (click)="changeAfghanistan('close')">
                      <span>National Museums</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/national-zoo']" (click)="changeAfghanistan('close')">
                      <span>National Zoo</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/agriculture']" (click)="changeAfghanistan('close')">
                      <span>Agriculture</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/water-dems-canals']" (click)="changeAfghanistan('close')">
                      <span>Water Dems & Canals</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['afghanistan/national-hotels']" (click)="changeAfghanistan('close')">
                      <span>National Hotels</span>
                    </a>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown showService dropdown-hover mx-1" >
                <a class="nav-link ps-2 d-flex cursor-pointer align-items-center menu-items" (click)="changeService('open')"  id="dropdownMenuPages"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="material-icons opacity-6 me-2 text-md">room_service</i>
                  Services
                  <img src="./assets/img/down-arrow-dark.svg" alt="down-arrow" class="arrow ms-auto ms-md-2">
                </a>
                <div class="dropdown-menu dropdown-menu-animation ms-n3 dropdown-md p-3 border-radius-xl mt-0 mt-lg-3"
                 aria-labelledby="dropdownMenuPages" #showService>
                  <div >
                    <a class="dropdown-item border-radius-md" [routerLink]="['service/flights']" (click)="changeService('close')">
                      <span>Flight Packages</span>
                    </a>
                    <!-- <a  class="dropdown-item border-radius-md" [routerLink] ="['service/packages']">
                      <span>Packages</span>
                    </a> -->
                    <a class="dropdown-item border-radius-md" [routerLink]="['service/insurance']" (click)="changeService('close')">
                      <span>Travel Insurance</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['service/visa-assistance']" (click)="changeService('close')">
                      <span>Visa Assistance</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['service/transportation']" (click)="changeService('close')">
                      <span>Airport Taxis</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['service/car-rental']" (click)="changeService('close')">
                      <span>Car Rentals</span>
                    </a>
                    <a  class="dropdown-item border-radius-md" [routerLink]="['service/hotel-parking']" (click)="changeService('close')">
                      <span>Hotel Booking</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['service/booking-scholorships']" (click)="changeService('close')">
                      <span>Scholorships</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['service/translator']" (click)="changeService('close')">
                      <span>Interprators for Tourist</span>
                    </a>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown show dropdown-hover mx-1">
                <a class="nav-link ps-2 d-flex cursor-pointer align-items-center menu-items" (click)="changeOppertunity('open')"  id="dropdownMenuPages"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="material-icons opacity-6 me-2 text-md">article</i>
                  Opportunities
                  <img src="./assets/img/down-arrow-dark.svg" alt="down-arrow" class="arrow ms-auto ms-md-2">
                </a>
                <div class="dropdown-menu dropdown-menu-animation ms-n3 dropdown-md p-3 border-radius-xl mt-0 mt-lg-3"
                  aria-labelledby="dropdownMenuPages" #showOppertunity >
                  <div>
                    <a class="dropdown-item border-radius-md" [routerLink]="['opportunities/vacany']" (click)="changeOppertunity('close')">
                      <span>Vacancies</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['opportunities/tender']" (click)="changeOppertunity('close')">
                      <span>Tender</span>
                    </a>
                    <a class="dropdown-item border-radius-md" [routerLink]="['opportunities/announcement']" (click)="changeOppertunity('close')" >
                      <span>Announcements</span>
                    </a>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown dropdown-hover mx-1">
                <a class="nav-link ps-2 d-flex cursor-pointer align-items-center menu-items"   id="dropdownMenuBlocks"
                  data-bs-toggle="dropdown" aria-expanded="false" [routerLink]="['about_us']">
                  <i class="material-icons opacity-6 me-2 text-md">view_day</i>
                  About Us
                </a>
              </li>
              <li class="nav-item dropdown dropdown-hover mx-1">
                <a class="nav-link ps-2 d-flex cursor-pointer align-items-center menu-items" id="dropdownMenuDocs"
                  data-bs-toggle="dropdown" aria-expanded="false" [routerLink]="['contact_us']">
                  <i class="material-icons opacity-6 me-2 text-md">contacts</i>
                  Contact Us
                  <!-- <img src="./assets/img/down-arrow-dark.svg" alt="down-arrow" class="arrow ms-auto ms-md-2"> -->
                </a>
              </li>
              <li class="nav-item dropdown show dropdown-hover mx-1" >
                <a class="nav-link ps-2 d-flex cursor-pointer align-items-center menu-items" (click)="changeAuth('open')"  id="dropdownMenuPages"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="material-icons opacity-8 me-0 text-md">group</i>
                  <img src="./assets/img/down-arrow-dark.svg" alt="down-arrow" class="arrow ms-auto ms-md-2">
                </a>
                <div class="dropdown-menu dropdown-menu-animation ms-n3 dropdown-md p-3 border-radius-xl mt-0 mt-lg-3"
                  aria-labelledby="dropdownMenuPages" #showAuth >
                  <div >
                    <a class="dropdown-item border-radius-md" *ngIf="!loggedIn" [routerLink]="['login']" (click)="changeAuth('close')" >
                      <span>Sign-In</span>
                    </a>
                    <a class="dropdown-item border-radius-md" *ngIf="!loggedIn" [routerLink]="['sign-up']" (click)="changeAuth('close')">
                      <span>Sign-Up</span>
                    </a>
                    <a class="dropdown-item border-radius-md" *ngIf="loggedIn" [routerLink]="['profile']" (click)="changeAuth('close')">
                      <span>Profile</span>
                    </a>
                    <a class="dropdown-item border-radius-md" *ngIf="loggedIn" (click)="logout()" (click)="changeAuth('close')" >
                      <span>Logout</span>
                    </a>

                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
    </div>
  </div>
</div>
