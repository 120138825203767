

<div class="container mt-12">
  <div class="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
    <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
      <div class="card mt-8">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div class="primary-bg shadow-success border-radius-lg py-3 pe-1 text-center py-4">
            <h4 class="font-weight-bolder text-white mt-1">Reset Account</h4>
            <p class="mb-1 text-sm text-white">Enter your email to recover your account</p>
            <span *ngIf="showError" style="color: red;">{{showErrorMsg}}</span>
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="resetForm" class="text-start">
            <div class="input-group input-group-outline mb-4">
              <input type="email" class="form-control" formControlName="email" placeholder="Enter Email Address">
            </div>
            <div class="text-center">
              <button type="button" class="btn primary-bg text-white w-100 mt-3 mb-0" (click)="reset()">Reset</button>
            </div>
          </form>
        </div>
        <div class="card-footer text-center pt-0 px-lg-2 px-1">
          <p class="mb-4 text-sm mx-auto">
            <a [routerLink]="['/login']" class="text-warning font-weight-bold">Login</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
