<header class="header-2" *ngIf="show">
  <div class="page-header min-vh-75" [ngStyle]="{'background-image' : 'url('+ bannerData?.image + ')'}">
    <span class="bg-gradient-dark opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center mx-auto my-auto">
          <h1 class="text-white">
            <div innerHtml="{{bannerData?.title}}"></div>
            <!-- {{bannerData?.title}} -->
          </h1>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="card card-body shadow-xl mx-3 mx-md-4 mt-n6">
  <section class="py-4" *ngIf="show">
    <div class="container-fluid">
      <div class="row" *ngFor="let item of aboutUsData">
        <div class="col-md-6 col-10 d-flex justify-content-center flex-column mx-auto text-lg-start text-center">
          <h2 style="color: #EDB25A ;">--- {{item?.title}} ---</h2>
          <!-- <h3 class="mb-4 ">WELCOME TO ARIA DELTA CONSULTING GROUP</h3> -->
          <!-- <p style="color: black;" class="mb-2 text-justify ">
            {{item?.description}}
           </p> -->
           <div class="text-justify" innerHtml="{{item?.description}}"></div>
        </div>
        <div class="col-md-5 col-6 mx-lg-0 mx-auto px-lg-0 px-md-0 my-auto">
         <div class="card">
          <div class="card-body">
            <div class="card-image">
            <img class="img border-radius-lg move-on-hover" width="100%" height="700" [src]="item?.image" alt="About-Us">
            </div>
          </div>
         </div>
        </div>
      </div>
    </div>
  </section>
  <!-- START Teams w/ 4 cards in a row w/ image & name & function -->
<section class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-8 mx-auto text-center">
        <h6 class="text-gradient text-warning">The Executive Team</h6>
        <h2>Building the future</h2>
      </div>
    </div>
    <div class="row mt-5" >
      <div class="col-xs-12 col-sm-12 col-md-3  mb-lg-0 mb-4 " *ngFor="let item of teamsData">
        <div class="card shadow-lg" >
          <!-- <div class="card-header ">
            <div class="card-image">
              <img class="img border-radius-lg move-on-hover" [src]="item?.image" alt="aboutUs" width="100%" height="300">
              </div>
              <img class="img-fluid shadow move-on-hover border-radius-lg"  [src]="item?.image" alt="img-blur-shadow">
          </div> -->
          <div class="card-body text-center bg-white border-radius-lg p-3 pt-0">
            <div class="card-image mt-2">
              <img class="img border-radius-lg move-on-hover" [src]="item?.image" alt="aboutUs" width="100%" height="250">
              </div>
            <h5 class="mt-3 mb-1 d-md-block d-none">{{item?.first_name}}  {{item?.last_name}}</h5>
            <p class="mb-1 d-md-none d-block text-sm font-weight-bold text-darker">{{item?.last_name}}</p>
            <p class="mb-0 text-xs font-weight-bolder text-warning text-gradient text-uppercase">{{item?.position}}</p>
            <p class="mb-0 text-xs font-weight-bolder ">{{item?.email}}</p>
          </div>
          <div class="card-footer">
            <div class="row ">
              <div class="col-md-12 input-group">
                <ul class="d-flex flex-row nav mx-0 mt-0 mb-0" *ngFor="let social of item?.social_team">
                  <li class="nav-item" *ngIf="social?.social_type == 'FACEBOOK'">
                    <a class="nav-link pe-2" [href]="social?.url" target="_blank">
                      <i class="fab fa-facebook text-lg opacity-8" style="color: #0165E1;"></i>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="social?.social_type == 'TWITTER'">
                    <a class="nav-link pe-2" [href]="social?.url" target="_blank">
                      <i class="fab fa-twitter text-lg opacity-8" style="color: #08a0e9;"></i>
                    </a>
                  </li>
                  <li  class="nav-item" *ngIf="social?.social_type == 'WHATSAPP'">
                    <a class="nav-link pe-2" [href]="social?.url" target="_blank">
                      <i class="fab fa-whatsapp text-lg opacity-8" style="color: #25D366;"></i>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="social?.social_type == 'LINKEDIN'">
                    <a class="nav-link pe-2" [href]="social?.url" target="_blank">
                      <i class="fab fa-linkedin text-lg opacity-8" style="color: #0A66C2;"></i>
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="social?.social_type == 'INSTAGRAM'">
                    <a class="nav-link pe-2" [href]="social?.url" target="_blank">
                      <i class="fab fa-instagram text-lg opacity-8" style="color: #0A66C2;"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br class="py-2">
      </div>
    </div>
    <div class="col-md-12 text-center text-dark my-3 text-sm font-weight-normal"  *ngIf="notShow">
      <a style=" cursor: pointer;" [routerLink]="['/about_us']" target="_blank" class="text-warning font-weight-bold">View More ....</a>
    </div>
  </div>
</section>
</div>


<!--Start Back To Top Button-->
<button role="button" class="back-to-top" *ngIf="show" (click)="toTop()"
  style="border-radius: 100%!important;right: 45px; bottom: 120px; position: fixed; display: inline;"><i
    class='fa fa-angle-up back-top white'></i></button>
<!--End Back To Top Button-->
