import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../../services/service.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OnlinePaymentModalComponent } from '../online-payment-modal/online-payment-modal.component';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';

@Component({
  selector: 'app-car-rentals',
  templateUrl: './car-rentals.component.html',
  styleUrls: ['./car-rentals.component.css']
})
export class CarRentalsComponent implements OnInit {

  public registerForm: FormGroup;
  public onlineModalRef: NgbModalRef;
  public offlineModalRef: NgbModalRef;
  public modalNumber = 0;

  constructor(
    private fb:FormBuilder,
    private router: Router,
    private service: ServiceService,
    private modal: NgbActiveModal,
    private ngbModal: NgbModal
  ) {
    this.ngbModal.activeInstances.subscribe(list => {
      this.modalNumber = list.length;
    })
   }

  ngOnInit(): void {

    this.initializeForm();
  }



  public initializeForm() {
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone_no: ['', Validators.required],
      car_info: ['', Validators.required],
      datetime: ['', Validators.required]
    });
  }

  registercarBooking(value) {
    if(value == 'register') {
      this.service.createRequest(this.registerForm.value)
        .subscribe({
          next: (request: any) => {
            if(request){
              this.modal.dismiss();
              this.router.navigate(['home/service/car-rental'])
            }
          }
        })
      // this.modal.close(this.registerForm.value);
    }else if(value == 'close') {
      this.modal.dismiss();
    }

  }



  public openPayment(type: string)  {
    if(type == 'online') {
      this.onlineModalRef = this.ngbModal.open(OnlinePaymentModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    }else if (type == 'offline') {
      this.offlineModalRef = this.ngbModal.open(PaymentModalComponent, {size: 'lg', backdrop: 'static', keyboard: false})
    }
  }

}
