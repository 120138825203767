import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OpportunityService {

  constructor(private http:HttpClient) { }

  getAnnouncement() {
    return this.http.get(`${environment.apiURL}/opportunity/announcement/`)
      .pipe(map((announcement)=>{
      return announcement;
      }))
  }
  getTender() {
    return this.http.get(`${environment.apiURL}/opportunity/tender/`)
      .pipe(map((tender)=>{
      return tender;
      }))
  }
  getVacancies() {
    return this.http.get(`${environment.apiURL}/opportunity/vacancy/`)
      .pipe(map((vacanceis)=>{
      return vacanceis;
      }))
  }


}



