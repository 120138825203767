<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header primary-bg mb-3">
        <h4 class="text-center" style="color: white;">{{"Register Your Information "}}</h4>
      </div>
      <div class="card-body">
        <form role="form" [formGroup]="registerForm">
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" formControlName="name" placeholder="Enter Full Name">
          </div>
          <div class="input-group input-group-outline mb-3">
            <input type="email" class="form-control" formControlName="email" placeholder="Enter Your Email">
          </div>
          <div class="input-group input-group-outline mb-3">
            <input type="number" class="form-control" formControlName="phone_no" placeholder="Enter Your Phone Number">
          </div>
          <div class="input-group input-group-outline mb-3">
            <textarea cols="10" rows="5" class="form-control" formControlName="hotel_name"
              placeholder="Enter Hotel Information"></textarea>
          </div>
          <div class="input-group input-group-outline mb-3">
            <input type="date" class="form-control" formControlName="datetime" placeholder="Enter The Date">
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body mb-3">
                  <h4 class="text-center" style="cursor: pointer;" (click)="openPayment('offline')">{{"Pay at Office"}}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-body mb-3">
                  <h4 class="text-center" style="cursor: pointer;" (click)="openPayment('online')">{{"Pay Now"}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn primary-bg text-white w-100 mt-4 mb-0"
                (click)="registerhotelBooking('register')">Register</button>
            </div>
            <div class="col-md-6">
              <button type="button" class="btn primary-bg text-white w-100 mt-4 mb-0"
                (click)="registerhotelBooking('close')">Close</button>
            </div>
          </div>
        </form>
      </div>


    </div>
  </div>
</div>
