<div class="card">
  <div class="card-header primary-bg">
    <h4 class="text-center" style="color: white;">You Can pay Online from here</h4>
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-3">
      <img src="assets/img/payment_images/paypal.png" alt="" width="100" height="100">
    </div>
    <div class="col-md-3">
      <img src="assets/img/payment_images/master_card.png" alt="" width="100" height="100">
    </div>
    <div class="col-md-3">
      <img src="assets/img/payment_images/visa.png" alt="" width="100" height="100">
    </div>
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-3">
      <img src="assets/img/payment_images/google_pay.png" alt="" width="100" height="100">
    </div>
    <div class="col-md-3">
      <img src="assets/img/payment_images/apple_pay.png" alt="" width="100" height="100">
    </div>
    <div class="col-md-3">
      <img src="assets/img/payment_images/bitcoin.png" alt="" width="100" height="100">
    </div>
  </div>

  <div class="card-footer">
    <div class="row">
    <div class="col-md-10"></div>
    <div class="col-md-2">
      <button type="button" class="btn primary-bg text-white"
        (click)="close()">Close</button>
    </div>
  </div>
</div>
</div>
