import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  public getNextPage(url: string) {
    return this.http.get(url)
      .pipe(map((data: any) => {
        return data;
      }))
  }

  public getPreviousPage(url: string) {
    return this.http.get(url)
      .pipe(map((data) => {
        return data;
      }))
  }


  search(searchText: string, searchModule: string, componentName: string) {
    return this.http.get(`${environment.apiURL}/${searchModule}/${componentName}?search=${searchText}`)
      .pipe(map((search) => {
        return search;
      }))
  }

  public register(data: any) {
    return this.http.post(`${environment.apiURL}/common/subscribe`, data)
      .pipe(map((registered) => {
        return registered
      }))
  }


  public createVisitor(value) {

    return this.http.post(`${environment.apiURL}/common/visitors`, { visit: value })
      .pipe(map((visitor) => {
        return visitor;
      }))
  }

  public getVisitors() {
    return this.http.get(`${environment.apiURL}/common/visitors`)
      .pipe(map((visitors) => {
        return visitors;
      }));
  }

  public updateVisitors(value, id) {
    return this.http.put(`${environment.apiURL}/common/visitors/${id}`, { visit: value })
      .pipe(map((visitorUpdate) => {
        return visitorUpdate;
      }))
  }

  public getVisitorById(id: number) {
    return this.http.get(`${environment.apiURL}/common/visitors/${id}`)
      .pipe(map((visitor) => {
        return visitor;
      }))
  }
}
