import { Component, OnInit, Input, Output } from '@angular/core';
import { AuthService } from '../Authentication/services/auth.service';
import { AboutUsService } from '../about-us/service/about-us.service';
import { BannerServiceService } from '../banner-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public isToggled = false;
  public bannerData: any = [];
  public showBanner = true;
  public teamsData: any=[];
  public ariadeltasocial: any=[];
  public facebook: any=[];
  public twitter: any=[];
  public instagram: any=[];
  public linkedin: any=[];
  public whatsappdata: any=[];
  public telegram: any=[];
  public tiktok: any=[];
  public messanger: any=[];
  public addressData: any=[];


  @Input() show: boolean  = true;
  @Input() limit: number = 100;

  public whatsapp: string = "https://api.whatsapp.com/send?phone="

  constructor(
    private authService:AuthService,
    private service: AboutUsService,
    private bannerService: BannerServiceService
    ) { }

  ngOnInit(): void {

    if(this.show) {
      this.showBanner = false;
    }
    this.bannerService.getBanners('Logo')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })


        this.service.getAddress()
        .subscribe({
          next: (response : any) => {
            this.addressData = response?.addresses?.results;
          },
          error: (err) => {
          },

        })




      this.getAriaDeltaSocial();
  }




  public getAriaDeltaSocial(){
    this.service.getAriaDeltaSocial()
    .subscribe({
      next: (response : any) => {
        this.ariadeltasocial = response?.ariadeltasocials?.results;

        this.facebook = this.ariadeltasocial.filter(function (item) {
          return item.social_type == 'FACEBOOK'
        });
        this.messanger = this.ariadeltasocial.filter(function (item) {
          return item.social_type == 'MESSENGER'
        });
        this.twitter = this.ariadeltasocial.filter(function (item) {
          return item.social_type == 'TWITTER'
        });
        this.linkedin = this.ariadeltasocial.filter(function (item) {
          return item.social_type == 'LINKEDIN'
        });
        this.instagram = this.ariadeltasocial.filter(function (item) {
          return item.social_type == 'INSTAGRAM'
        });
        this.telegram = this.ariadeltasocial.filter(function (item) {
          return item.social_type == 'TELEGRAM'
        });
        this.whatsappdata = this.ariadeltasocial.filter(function (item) {
          return item.social_type == 'WHATSAPP'
        });
        this.tiktok = this.ariadeltasocial.filter(function (item) {
          return item.social_type == 'TIKTOK'
        });
        this.whatsappdata = this.whatsapp.concat(this.whatsappdata[0]?.phone)

      },
      error: (err) => {
      }

    })

  }

  toggleClass() {
    this.isToggled = !this.isToggled;
  }

}
