<div class="container-fixed">
  <div class="row">
    <div class="col-md-12">
      <app-header></app-header>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12">
      <section >
        <app-footer></app-footer>
      </section>
    </div>
  </div>

</div>




