<header class="header-2">
  <div class="page-header min-vh-75 relative" [ngStyle]="{'background-image' : 'url('+ bannerData?.image + ')'}">
    <span class="opacity-4"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-7 text-center mx-auto">
          <h1 class="text-white pt-3 mt-n5">{{bannerData?.title}}</h1>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="card card-body blur shadow-blur mx-0 mx-md-5 mt-n6">
  <section class="pt-0 pb-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="input-group input-group-outline">
            <input class="form-control " type="text" placeholder="Search" name="searchText" [(ngModel)]="searchText"
              autocomplete="off" style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
            <button type="button" class="btn primary-bg text-white btn-fw "
              style="border-top-right-radius: 10px; border-bottom-right-radius: 10px; height: 35px;">Search</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mx-auto py-3">
          <app-calendar></app-calendar>
        </div>

        <div class="col-lg-4 mx-auto py-3">
          <app-converter></app-converter>
        </div>
        <div class="col-lg-4 mx-auto py-3">
          <app-date-converter></app-date-converter>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="pt-3 pb-0">
    <ng-adsense [adClient]="'ca-pub-5330811808507046'" [adSlot]="7259870550" [display]="'inline-block'" [width]="300"
      [height]="40"></ng-adsense>
  </section> -->
</div>
<section class="pt-3 pb-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto py-3 ">
        <app-booking-scholorships [show]="false" [notShow]="true" [limit]="3"></app-booking-scholorships>
      </div>
    </div>
  </div>
</section>
<section class="pt-3 pb-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto py-3 ">
        <app-visa-assistance [show]="false" [notShow]="true" [limit]="3"></app-visa-assistance>
      </div>
    </div>
  </div>
</section>
<section class="pt-3 pb-4 ">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto py-3">
        <app-flights [show]="false" [notShow]="true" [limit]="3"></app-flights>
      </div>
    </div>
  </div>
</section>
<section class="pt-3 pb-4 ">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto py-3">
        <app-announcements [show]="false" [notShow]="true" [limit]="3"></app-announcements>
      </div>
    </div>
  </div>
</section>
<section class="pt-3 pb-4 ">
  <div class="container-fluid bg-gradient">
    <div class="row">
      <div class="col-lg-12 mx-auto py-3 ">
        <app-about-us [show]="false" [notShow]="true" [limit]="4"></app-about-us>
      </div>
    </div>
  </div>
</section>
<div class="card card-body mx-3 mx-md-4 mt-n4 b" style="background-color: rgba(246, 186, 166, 0.658);">
  <section class="pt-1 pb-1 " >
    <div class="container-fluid">
      <div class="row">
        <h4 class="mb-2 text-black text-bold text-center" style="margin-right: 30px;"> Subscribe to our News Letter </h4>
        <div class="col-lg-2">
          <span class="text-bold" *ngIf="successAlert">{{successMsg}}</span>
          <span class="text-bold" *ngIf="errorAlert">{{errorMsg}}</span>

        </div>
        <div class="col-lg-8 mx-auto py-3 ">

          <form [formGroup]="subscribeForm">
          <div class="row">
            <div class="col-8">
              <div class="input-group ">
                <input type="email" class="form-control" formControlName="email" placeholder="Enter Email Address">
              </div>
            </div>
            <div class="col-4 ps-0">
              <button type="button"
                class="btn primary-bg text-white mb-0 h-100 position-relative z-index-2" (click)="subscribe()">Subscribe</button>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  </section>
</div>
<hr>
<div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n4">
  <section class="pt-3 pb-4 ">
    <div class="container-fluid">
      <div class="row">
        <!-- <div class="col-md-2"></div> -->
        <div class="col-md-12 text-center">
          <h5 class="mb-3 text-warning text-center">Total Visitors</h5>
          <h5 class="text-warning text-center">{{totalVisitors}} </h5>
        </div>
        <!-- <div class="col-md-3"></div> -->
      </div>
    </div>
  </section>
</div>


<!--start overlay-->
<div class="overlay nav-toggle-icon"></div>
<!--end overlay-->

<!--Start Back To Top Button-->
<button role="button" class="back-to-top" (click)="toTop()"
  style="border-radius: 100%!important;right: 45px; bottom: 120px; position: fixed; display: inline;"><i
    class='fa fa-angle-up back-top white'></i></button>
<!--End Back To Top Button-->

