import { Component, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../services/auth.service'
import { BannerServiceService } from 'src/app/banner-service.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  public bannerData: any = [];
  public user: any = [];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Output() showBan : boolean = true;

  public userData;
  constructor(
    private authService: AuthService,
    private bannerService:BannerServiceService,
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser()
      .subscribe({
        next: (user:any) => {
          this.userData = user?.authenticatedUser;
        }
      })


      this.bannerService.getBanners('Profile')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })


  }




}
