import { Component, OnInit, Input, Output } from '@angular/core';
import { ServiceService } from '../../services/service.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SocialComponent } from '../../modals/social/social.component';

@Component({
  selector: 'app-visa-assistance',
  templateUrl: './visa-assistance.component.html',
  styleUrls: ['./visa-assistance.component.css']
})
export class VisaAssistanceComponent implements OnInit {
  public bannerData: any = [];
  public visaAssistanceData: any=[];
  public showBanner = true;
  public shareModelRef: NgbModalRef;
  @Input() show: boolean  = true;
  @Input() notShow: boolean  = false;
  @Input() limit: number = 100;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  public searchText;

  constructor(
    private service: ServiceService,
    private bannerService: BannerServiceService,
    private commonService:CommonService,
    private modal : NgbModal
  ) { }

  ngOnInit(): void {
    this.bannerService.getBanners('Service_VisaAssistance')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;

        }
      })
    this.getVisaAssistance();
  }

  public getVisaAssistance(){
    this.service.getVisaAssistance()
    .subscribe({
      next: (response : any) => {
        if(this.limit == 100){
          this.visaAssistanceData = response?.visaassistances?.results;
        }else if(this.limit != 100) {
          for(let i =1; i <=3; i++) {
            this.visaAssistanceData.push(response?.visaassistances?.results[i]);
          }
        }

      },

      error: (err) => {

      },

    })

  }

  search() {
    this.commonService.search(this.searchText, 'services', 'flights')
    .subscribe({
      next: (result) => {
        this.visaAssistanceData = result['visaassistances']?.results;
      }
    })
}

public Share() {
  this.shareModelRef = this.modal.open(SocialComponent, { keyboard: false, size: 'sm',  backdrop: true, backdropClass: "zIndex"});
  this.shareModelRef.closed.subscribe(value => {
    // if (value) {
    //   this.service.createRequest(value)
    //     .subscribe(carBooking => {
    //       this.ngOnInit();
    //     })
    // }
  })

}

public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.visaAssistanceData = response?.visaassistances?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.visaassistances?.previous;
            this.nextLink = response?.visaassistances?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.visaassistances?.next;
              this.previousLink = response?.visaassistances?.previous;
              this.visaAssistanceData = response?.visaassistances?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}

toTop() {
  window.scrollTo(0, 0)
}

}
