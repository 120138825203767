import { Component, OnInit, Input, Output} from '@angular/core';
import { ServiceService } from '../../services/service.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';
import { SocialComponent } from '../../modals/social/social.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-booking-scholorships',
  templateUrl: './booking-scholorships.component.html',
  styleUrls: ['./booking-scholorships.component.css']
})
export class BookingScholorshipsComponent implements OnInit {
  public bannerData: any = [];
  public bookingScholorshipsData: any[] = [];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Input() notShow: boolean  = false;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  @Input() limit: number = 100;
  public searchText;
  public shareModelRef: NgbModalRef;


  constructor(
    private service : ServiceService,
    private bannerService: BannerServiceService,
    private commonService: CommonService,
    private modal : NgbModal,
  ) {}

  ngOnInit(): void {
    this.bannerService.getBanners('Service_BookingScholorship')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;

        }
      })
    this.getBookingScholorships();
  }

  public getBookingScholorships(){
    this.service.getBookingScholorships()
    .subscribe({
      next: (response : any) => {
        if(this.limit == 100){
          this.bookingScholorshipsData = response?.booking_scholoships?.results;
        }else if(this.limit != 100) {
          for(let i =1; i <=3; i++) {
            this.bookingScholorshipsData.push(response?.booking_scholoships?.results[i]);
          }
        }
      },
      error: (err) => {
      },

    })

  }

  search() {
    this.commonService.search(this.searchText, 'services', 'booking-scholorship')
    .subscribe({
      next: (result) => {
        this.bookingScholorshipsData = result['booking_scholoships']?.results;
      }
    })
}

public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.bookingScholorshipsData = response?.booking_scholoships?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.booking_scholoships?.previous;
            this.nextLink = response?.booking_scholoships?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.booking_scholoships?.next;
              this.previousLink = response?.booking_scholoships?.previous;
              this.bookingScholorshipsData = response?.booking_scholoships?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}


public Share(scholarshipName: string, imageUrl: string) {
  console.log("Name:", JSON.stringify(scholarshipName));
  console.log("Image:", imageUrl);
  this.shareModelRef = this.modal.open(SocialComponent, { keyboard: false, size: 'sm',  backdrop: true, backdropClass: "zIndex"});
  this.shareModelRef.componentInstance.scholarshipName = scholarshipName;
  this.shareModelRef.componentInstance.imageUrl = imageUrl;
  this.shareModelRef.closed.subscribe(value => {
  });
}

  toTop() {
    window.scrollTo(0, 0)
  }

}
