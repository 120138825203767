import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vacancy-details',
  templateUrl: './vacancy-details.component.html',
  styleUrls: ['./vacancy-details.component.css']
})
export class VacancyDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
