import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService:AuthService, private router:Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const access_token = this.authService.getAccessToken();


    if(access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${access_token}`
        }
      })
    }


    return next.handle(request).pipe(tap(() => {}, (err: any) => {
      if(err instanceof HttpErrorResponse) {
        if(err.status === 401) {
          this.router.navigate(['login']);
        }
        this.router.navigate(['/'])
      }
    }))
  }
}
