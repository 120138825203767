
<header class="header-2" *ngIf="show">
  <div class="page-header min-vh-75 relative" [ngStyle]="{'background-image' : 'url('+ bannerData?.image + ')'}">
    <span class="bg-gradient-primary opacity-4"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-7 text-center mx-auto">
          <h1 innerHtml="{{bannerData?.title}}" class="text-white pt-3 mt-n5"></h1>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n4">
  <section class="pt-3 pb-4" id="count-stats">
    <div class="container">
      <div class="row" *ngIf="show">
        <div class="col-md-6"></div>
          <div class="col-md-6">
            <div class="input-group input-group-outline">
              <input class="form-control " type="text" placeholder="Search" name="searchText"
              [(ngModel)]="searchText" autocomplete="off"
              style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
              <button type="button" class="btn primary-bg text-white btn-fw "
              style="border-top-right-radius: 10px; border-bottom-right-radius: 10px; height: 35px;">Search</button>
            </div>
          </div>
      </div>
      <div class="row">
        <h3 class="text-center mb-5">Scholarships</h3>
        <div class="col-md-4" *ngFor="let item of bookingScholorshipsData | filter:searchText">
          <div class="card">
            <div class="card-header primary-bg">
              <h4 innerHtml="{{item?.scholorship_name}}" class="text-center" style="color: white;"></h4>
            </div>
            <div class="card-body">
              <div class="card-image">
                <img class="img border-radius-lg move-on-hover" [src]="item?.image" alt="Booking-Scholorships" width="100%" height="300">
                </div>
              <div class="row">
                <div class="col-md-12 ">
                  <h6 class=" text-center mt-3 mb-0 d-md-block d-none text-warning ">{{'Scholorship Country'}}:  {{item?.scholorship_country}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 ">
                  <h6 class="text-center mt-0 mb-0 text-warning" >{{'Scholorship Amount'}}:  {{item?.scholorship_amount}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 ">
                  <h6 class="mt-0 mb-0  text-justify" [innerHTML]=" item?.description"></h6>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12">
                  <h6 class="text-center mt-0 mb-2 text-warning">{{item?.updated_at | date:'mediumDate'}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-6">
                  <img class="text-center mt-0 mb-0 text-warning" src="../../../../assets/share48.png" width="30" alt="Share" (click)="Share(item?.scholorship_name, item?.image)">
                </div>
              </div>
            </div>
          </div>
          <br class="py-2">
        </div>
      </div>
      <div class="col-md-12 text-center text-dark my-3 text-sm font-weight-normal" *ngIf="notShow">
        <a style=" cursor: pointer;" [routerLink]="['/booking-scholorships']" target="_blank" class="text-warning font-weight-bold">View More ....</a>
      </div>
    </div>
  </section>
  <div class="row" *ngIf="show">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center" >
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item">
            <a class="page-link" (click)="previous()" tabindex="-1">
              <i class="fa fa-angle-left"></i>
            </a>
          </li>
          <li class="page-item active "><a class="page-link" href="javascript:;">{{pageNumber}}</a></li>
          <li class="page-item">
            <a class="page-link" (click)="next()">
              <i class="fa fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>

<!--Start Back To Top Button-->
<button role="button" class="back-to-top" (click)="toTop()" *ngIf="show"
  style="border-radius: 100%!important;right: 45px; bottom: 120px; position: fixed; display: inline;"><i
    class='fa fa-angle-up back-top white'></i></button>
<!--End Back To Top Button-->
