import { Component, OnInit, Input, Output } from '@angular/core';
import { AfghanistanService } from '../../services/afghanistan.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';

@Component({
  selector: 'app-national-museums',
  templateUrl: './national-museums.component.html',
  styleUrls: ['./national-museums.component.css']
})
export class NationalMuseumsComponent implements OnInit {
  public bannerData: any = [];
  public nationalMuseumsData: any=[];
  public showBanner = true;
  @Input() show: boolean  = true;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  public searchText;

  constructor(
    private service: AfghanistanService,
    private bannerService: BannerServiceService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.bannerService.getBanners('Afg_NationalMuseums')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })
    this.getNationalMuseums();
  }

  public getNationalMuseums(){
    this.service.getNationalMuseums()
    .subscribe({
      next: (response : any) => {
        this.nationalMuseumsData = response?.national_museums?.results;
        this.nextLink = response?.national_museums.next;
      },
      error: (err) => {
      },

    })

  }

  search() {
    this.commonService.search(this.searchText, 'afghanistan', 'national-museum')
    .subscribe({
      next: (result) => {
        this.nationalMuseumsData = result['national_museums']?.results;
      }
    })
}

public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.nationalMuseumsData = response?.national_museums?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.national_museums?.previous;
            this.nextLink = response?.national_museums?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.national_museums?.next;
              this.previousLink = response?.national_museums?.previous;
              this.nationalMuseumsData = response?.national_museums?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}
  toTop() {
    window.scrollTo(0, 0)
  }
}
