import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AuthService } from '../Authentication/services/auth.service';
import { Router } from '@angular/router';
import { BannerServiceService } from '../banner-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public loggedIn: boolean = false;
  public bannerData: any = [];
  public showBanner = true;
  isMobileMenuOpen = false;

  @ViewChild('showAfghanistan', {static: true}) showAfghanistan: ElementRef;
  @ViewChild('showService', {static: true}) showService: ElementRef;
  @ViewChild('showOppertunity', {static: true}) showOppertunity: ElementRef;
  @ViewChild('showAuth', {static: true}) showAuth: ElementRef;

  @Input() show: boolean  = true;
  @Input() limit: number = 100;

  constructor(
    private authService:AuthService,
    private router:Router,
    private bannerService: BannerServiceService,
    private render: Renderer2
    ) { }

  ngOnInit(): void {
    this.authService.LoginStatus.subscribe({
      next: (value) => {
        if(value) {
          this.loggedIn = true;
        }else {
          this.loggedIn = false;
        }
      }
    })

    if(this.show) {
      this.showBanner = false;
    }
    this.bannerService.getBanners('Logo')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })

  }

    // Function to toggle the mobile menu
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }

  logout() {
    this.authService.LoginStatus.next(false);
    this.router.navigate(['login']);
  }

  public changeAfghanistan(value) {
    if(this.isMobileMenuOpen) {
      if(value == 'open') {
        this.render.addClass(this.showAfghanistan.nativeElement, 'show');
        this.render.addClass(this.showAfghanistan.nativeElement, 'text-color')
      }else if(value == 'close') {
        this.render.removeClass(this.showAfghanistan.nativeElement, 'show')
      }
    }
  }
  public changeService(value) {
    if(this.isMobileMenuOpen) {
      if(value == 'open') {
        this.render.addClass(this.showService.nativeElement, 'show');
        this.render.addClass(this.showService.nativeElement, 'text-color')
      }else if(value == 'close') {
        this.render.removeClass(this.showService.nativeElement, 'show')
      }
    }
  }
  public changeOppertunity(value) {
    if(this.isMobileMenuOpen) {
      if(value == 'open') {
        this.render.addClass(this.showOppertunity.nativeElement, 'show');
        this.render.addClass(this.showOppertunity.nativeElement, 'text-color')
      }else if(value == 'close') {
        this.render.removeClass(this.showOppertunity.nativeElement, 'show')
      }
    }
  }
  public changeAuth(value) {
    if(this.isMobileMenuOpen) {
      if(value == 'open') {
        this.render.addClass(this.showAuth.nativeElement, 'show');
        this.render.addClass(this.showAuth.nativeElement, 'text-color')
      }else if(value == 'close') {
        this.render.removeClass(this.showAuth.nativeElement, 'show')
      }
    }
  }


}
