import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceComponent } from './service.component';
import { FlightsComponent } from './components/flights/flights.component';
import { PackagesComponent } from './components/packages/packages.component';
import { HotelParkingComponent } from './components/hotel-parking/hotel-parking.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { TranslatorsComponent } from './components/translators/translators.component';
import { TransportationComponent } from './components/transportation/transportation.component';
import { VisaAssistanceComponent } from './components/visa-assistance/visa-assistance.component';
import { BookingScholorshipsComponent } from './components/booking-scholorships/booking-scholorships.component';
import { CarRentalComponent } from './components/car-rental/car-rental.component';

const routes: Routes = [
  {
    path: '',
    component: ServiceComponent
  },
  {
    path:'flights',
    component:FlightsComponent
  },
  {
    path:'packages',
    component: PackagesComponent
  },
  {
    path:'hotel-parking',
    component: HotelParkingComponent
  },
  {
    path:'insurance',
    component: InsuranceComponent
  },
  {
    path:'translator',
    component: TranslatorsComponent
  },
  {
    path:'transportation',
    component: TransportationComponent
  },
  {
    path:'visa-assistance',
    component: VisaAssistanceComponent
  },
  {
    path:'booking-scholorships',
    component: BookingScholorshipsComponent
  },
  {
    path:'car-rental',
    component: CarRentalComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceRoutingModule { }
