<div class="card">
  <div class="card-header primary-bg">
    <h4 class="text-center" style="color: white;">For Offline Payment Please Visit Our Office</h4>
  </div>
  <div class="card-body">

    Kabul office: Hous# 9, Street# 4, Kulula Puhsta, Kabul Afghanistan <br>
    Phone: +93(0)773108181 <br>
    Email: info@ariadeltravel.com

  </div>

  <div class="card-footer">
    <div class="row">
    <div class="col-md-10"></div>
    <div class="col-md-2">
      <button type="button" class="btn primary-bg text-white"
        (click)="close()">Close</button>
    </div>
  </div>
</div>
</div>

