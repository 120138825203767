import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public bannerData: any = [];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Output() showBan : boolean = true;
  public showError: boolean = false;
  public showErrorMsg: string = '';

  public resetForm:FormGroup;
  constructor(private authService:AuthService, private fb:FormBuilder) { }

  ngOnInit(): void {
    this.initializeForm();
  }


  public initializeForm() {
    this.resetForm = this.fb.group({
      email: ['', Validators.required]
    })
  }

  public reset() {
  }
}
