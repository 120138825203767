
<header class="header-2" *ngIf="show">
  <div class="page-header min-vh-75 relative" [ngStyle]="{'background-image' : 'url('+ bannerData?.image + ')'}">
    <span class="bg-gradient-primary opacity-4"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-7 text-center mx-auto">
          <h1 innerHtml="{{bannerData?.title}}" class="text-white pt-3 mt-n5"></h1>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6" >
  <section class="pt-3 pb-4" id="count-stats">
    <div class="container">
      <div class="row">
        <div class="col-md-4" *ngFor="let item of packagesData ">
          <div class="card">
            <div class="card-header primary-bg">
              <h4 innerHtml="{{item?.title}}" class="text-center" style="color: white;"></h4>
            </div>
            <div class="card-body">
              <div class="card-image">
                <img class="img border-radius-lg move-on-hover" [src]="item?.image" alt="Booking-Scholorships" width="100%" height="300">
                </div>
                <br class="py-4">
              <div class="row">
                <div class="col-md-12 ">
                  <h6 class="text-center mt-0 mb-0 text-warning">{{'Adress'}}:  {{item?.address}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 ">
                  <h6 class="text-center mt-0 mb-0 text-warning"> {{'Phone'}}:   {{item?.phone}}</h6>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12">
                  <h6 class="text-center mt-0 mb-0 text-warning">{{'Updated'}} :{{item?.updated_at | date:'mediumDate'}}</h6>
                </div>
              </div>
            </div>
          </div>
          <br class="py-2">
        </div>
      </div>
    </div>
  </section>
  <!-- <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center" >
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item">
            <a class="page-link" (click)="previous()" tabindex="-1">
              <i class="fa fa-angle-left"></i>
            </a>
          </li>
          <li class="page-item active "><a class="page-link" href="javascript:;">{{pageNumber}}</a></li>
          <li class="page-item">
            <a class="page-link" (click)="next()">
              <i class="fa fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4"></div>
  </div>
</div> -->


<!--Start Back To Top Button-->
<button role="button" class="back-to-top" (click)="toTop()"
  style="border-radius: 100%!important;right: 45px; bottom: 120px; position: fixed; display: inline;"><i
    class='fa fa-angle-up back-top white'></i></button>
<!--End Back To Top Button-->
