import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css']
})
export class SocialComponent implements OnInit {
  postTitle = 'Your Post Title';
  postContent = 'Your post content goes here.';
  @Input() scholarshipName: string;
  @Input() imageUrl: string;

  constructor(
    private modal: NgbActiveModal,
    private meta: Meta

  ) {
  }

  ngOnInit(): void {
    // this.meta.updateTag({ property: 'og:title', content: this.scholarshipName });
    // this.meta.updateTag({ property: 'og:image', content: this.imageUrl });
  }


  shareOnFacebook() {
    const url = encodeURIComponent(window.location.href);
    const name = encodeURIComponent(this.scholarshipName);
    const image = encodeURIComponent(this.imageUrl);
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${name}&picture=${image}`;
    window.open(shareUrl, 'facebook-share', 'width=600,height=400');
    this.modal.dismiss();



  }

  shareOnTwitter() {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Check out this post: ' + this.postTitle);
    window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}`, 'twitter-share', 'width=600,height=400');
    this.modal.dismiss()

  }
  shareOnLinkedIn() {
    const url = encodeURIComponent(window.location.href);
    window.open(`https://www.linkedin.com/shareArticle?url=${url}&title=${this.postTitle}`, 'linkedin-share', 'width=600,height=400');
    this.modal.dismiss()
  }
  shareOnWhatsApp() {
    const url = encodeURIComponent(window.location.href);
    window.open(`whatsapp://send?text=${encodeURIComponent('Check out this post: ' + this.postTitle + ' ' + url)}`, '_blank');
    this.modal.dismiss()
  }

  shareOnInstagram() {
    const url = encodeURIComponent(window.location.href);
    const caption = encodeURIComponent('Check out this post: ' + this.postTitle);

    const instagramUrl = `instagram://library?AssetPath=${url}&Caption=${caption}`;

    // Try to open Instagram app on the user's mobile device
    window.location.href = instagramUrl;

    // If the Instagram app is not installed, provide a fallback link to the Instagram website with target="_blank"
    setTimeout(() => {
      window.open(`https://www.instagram.com/create/caption/?media_url=${url}&caption=${caption}`, '_blank');
    }, 500);
    this.modal.dismiss()
  }

}
