<header class="header-2">
  <div class="page-header min-vh-75 relative" style="background-image: url('../../../assets/img/bg5.jpg');">
    <span class="mask  opacity-4"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-7 text-center mx-auto">
          <h1 class="text-white pt-3 mt-n5">Terms & Conditions</h1>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="card card-body blur shadow-blur mx-3 mx-md-4 mt-n6">
  <section class="pt-3 pb-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h3>Terms and Conditions for Aria Delta Travel and Tours</h3><br/><br/>
          <p class="text-justify">

Please read these Terms and Conditions carefully before using the services provided by Aria Delta Travel and Tours. By accessing or using our website, booking services, or interacting with our company, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our services.<br/><br/>

Booking and Payment<br/><br/>
1.1. Bookings: All bookings are subject to availability. By making a booking through Aria Delta Travel and Tours, you confirm that you are at least 18 years old and possess the legal authority to enter into contractual agreements.<br/>
1.2. Accuracy of Information: You are responsible for providing accurate and up-to-date information when making a booking. Aria Delta Travel and Tours will not be held liable for any issues arising from incorrect or incomplete information provided by you.<br/>
1.3. Pricing: Prices displayed on our website are subject to change and availability. We strive to provide accurate and competitive pricing, but errors may occur. In the event of a pricing error, we reserve the right to cancel or modify the booking.<br/>
1.4. Payment: Full payment is required at the time of booking, unless otherwise stated. We accept major credit cards, bank transfers, and online payment platforms as specified on our website.<br/><br/>

Cancellations and Modifications<br/><br/>
2.1. Cancellations by You: Cancellation policies vary depending on the type of service booked (flights, hotels, tours, etc.) and the specific terms and conditions of the suppliers. It is your responsibility to review and understand the cancellation policy before making a booking. Cancellation fees may apply.<br/>
2.2. Modifications by You: If you need to make changes to your booking, such as travel dates, passenger names, or itinerary adjustments, additional fees may apply. Changes are subject to availability and the terms and conditions of the suppliers.<br/>
2.3. Cancellations and Modifications by Aria Delta Travel and Tours: In rare circumstances, we may need to cancel or modify your booking. If this occurs, we will make reasonable efforts to notify you as soon as possible and offer alternative arrangements. In such cases, our liability will be limited to the refund of the amount paid for the affected booking.<br/><br/>

Travel Documents and Requirements<br/><br/>
3.1. Passports and Visas: It is your responsibility to ensure that you have a valid passport and any required visas or travel documents for your destination(s). Aria Delta Travel and Tours can provide general information and guidance, but we do not assume responsibility for verifying or obtaining your travel documents.<br/>
3.2. Health and Safety: It is your responsibility to obtain any necessary vaccinations and health clearances before traveling. We recommend consulting with a medical professional and checking the travel advisories issued by your government for your destination(s).<br/><br/>

Limitation of Liability<br/><br/>
4.1. Aria Delta Travel and Tours acts as an intermediary between you and the suppliers of travel services. We do not own or operate airlines, hotels, or other service providers. Therefore, we shall not be liable for any loss, injury, delay, damage, or inconvenience caused by the acts or omissions of such suppliers.<br/>
4.2. Aria Delta Travel and Tours will not be liable for any indirect, incidental, consequential, or punitive damages arising from your use of our services or any errors, inaccuracies, or omissions in our website or content.<br/><br/>

Intellectual Property<br/><br/>
5.1. All content on the Aria Delta Travel and Tours website, including text, graphics, logos, images, and software, is the property of Aria Delta Travel and Tours and protected by intellectual property laws. You may not reproduce, modify, distribute, or use our content without prior written consent.<br/><br/>

Governing Law and Jurisdiction<br/><br/>
6.1. These Terms and Conditions are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].<br/><br/>

Changes to Terms and Conditions<br/><br/>
7.1. Aria Delta Travel and Tours reserves the right to modify or update these Terms and Conditions at any time. Changes will be effective immediately upon posting on our website. We recommend reviewing these terms periodically for any updates.<br/><br/>

If you have any questions or concerns regarding these Terms and Conditions, please contact us using the following details:<br/><br/>

Aria Delta Travel and Tours<br/>
Address: [Address]<br/>
Email: [Email Address]<br/>
Phone: [Phone Number]<br/><br/>

Thank you for choosing Aria Delta Travel and Tours.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>

