import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OpportunitiesComponent } from './opportunities.component';
import { VacanciesComponent } from './components/vacancies/vacancies.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { TenderComponent } from './components/tender/tender.component';

const routes: Routes = [
  { path: '',
   component: OpportunitiesComponent
  },
  {
    path:'vacany',
    component:VacanciesComponent
  },
  {
    path: 'announcement',
    component:AnnouncementsComponent
  },
  {
    path: 'tender',
    component:TenderComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OpportunitiesRoutingModule { }
