<div class="page-header align-items-start min-height-300 m-3 border-radius-xl"
[ngStyle]="{'background-image' : 'url('+ bannerData?.image + ')'}" loading="lazy">
  <span class="bg-gradient-dark opacity-6"></span>
</div>

<div class="container mb-4">
  <div class="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
    <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
      <div class="card mt-8">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div class="primary-bg shadow-success border-radius-lg py-3 pe-1 text-center py-4">
            <h4 class="font-weight-bolder text-white mt-1">Sign In</h4>
            <p class="mb-1 text-sm text-white">Enter your email and password to Sign In</p>
            <span *ngIf="showError" style="color: red;">{{showErrorMsg}}</span>
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="loginForm" class="text-start">
            <div class="input-group input-group-outline mb-4">
              <input type="email" class="form-control" formControlName="email" placeholder="Enter Email Address">
            </div>
            <div class="input-group input-group-outline mb-4">
              <input type="password" class="form-control" formControlName="password" placeholder="Enter Your Password">
            </div>
            <div class="input-group input-group-outline">
              <a style="cursor: pointer;" class="move-on-hover"  (click)="forgotPassword()">forgot password ? </a>
            </div>
            <div class="text-center">
              <button type="button" class="btn primary-bg text-white w-100 mt-3 mb-0" (click)="login()">Sign in</button>
            </div>
          </form>
        </div>
        <div class="card-footer text-center">
          <p class="mb-4 text-sm ">
            Don't have an account?
            <a [routerLink]="['/sign-up']" class="move-on-hover font-weight-bold">Register</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
