import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';


import { AfghanistanRoutingModule } from './afghanistan-routing.module';
import { AfghanistanComponent } from './afghanistan.component';
import { HistoryComponent } from './components/history/history.component';
import { CultureTraditionComponent } from './components/culture-tradition/culture-tradition.component';
import { HistoricPlacesComponent } from './components/historic-places/historic-places.component';
import { NationalParksComponent } from './components/national-parks/national-parks.component';
import { NationalMuseumsComponent } from './components/national-museums/national-museums.component';
import { NationalZooComponent } from './components/national-zoo/national-zoo.component';
import { AgricultureComponent } from './components/agriculture/agriculture.component';
import { WaterDemsCanalsComponent } from './components/water-dems-canals/water-dems-canals.component';
import { NationalHotelsComponent } from './components/national-hotels/national-hotels.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceRoutingModule } from '../service/service-routing.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AgricultureDetailsComponent } from './components/agriculture/agriculture-details/agriculture-details.component';
import { HistoryDetailsComponent } from './components/history/history-details/history-details.component';
import { CultureTraditionDetailsComponent } from './components/culture-tradition/culture-tradition-details/culture-tradition-details.component';
import { HistoricPlacesDetailsComponent } from './components/historic-places/historic-places-details/historic-places-details.component';
import { NationalParkDetailsComponent } from './components/national-parks/national-park-details/national-park-details.component';
import { NationalMuseumDetailsComponent } from './components/national-museums/national-museum-details/national-museum-details.component';
import { NationalZooDetailsComponent } from './components/national-zoo/national-zoo-details/national-zoo-details.component';
import { WdcDetailsComponent } from './components/water-dems-canals/wdc-details/wdc-details.component';
import { NationalHotelDetailsComponent } from './components/national-hotels/national-hotel-details/national-hotel-details.component';


@NgModule({
  declarations: [
    AfghanistanComponent,
    HistoryComponent,
    CultureTraditionComponent,
    HistoricPlacesComponent,
    NationalParksComponent,
    NationalMuseumsComponent,
    NationalZooComponent,
    AgricultureComponent,
    WaterDemsCanalsComponent,
    NationalHotelsComponent,
    AgricultureDetailsComponent,
    HistoryDetailsComponent,
    CultureTraditionDetailsComponent,
    HistoricPlacesDetailsComponent,
    NationalParkDetailsComponent,
    NationalMuseumDetailsComponent,
    NationalZooDetailsComponent,
    WdcDetailsComponent,
    NationalHotelDetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    AfghanistanRoutingModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule
  ],
  exports: []
})
export class AfghanistanModule { }
