import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, map } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isLoggedIn = false;
  private user;
  private login_error;
  public LoginStatus = new BehaviorSubject<boolean>(false);


  constructor(private http:HttpClient, private router:Router) { }

  storeToStorage(key: string, value: any) {
    localStorage.setItem(key, value);
    sessionStorage.setItem(key, value);
  }

  removeFromStorage(key: string){
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }


 public deltaLogin(email: string, password: string) {

    return this.http.post(`${environment.apiURL}/common/login/`, {email: email, password: password})
      .pipe(map((login) => {
        return login;
      }))
  }

  getToken(email: string, password: string) {
    this.http.post(`${environment.apiURL}/common/token/`,{email: email, password: password})
      .subscribe({
        next: (token) => {
          this.storeToStorage('access_token', token['access']);
          this.storeToStorage('refresh_token', token['refresh']);
        }
      })
  }


  public register(data: any) {
    return this.http.post(`${environment.apiURL}/common/register`, data)
      .pipe(map((registered) => {
        return registered
      }))
  }

  getCurrentUser() {
    return this.http.get(`${environment.apiURL}/common/authenticated`)
      .pipe(map((authenticatedUser) => {
        return authenticatedUser;
      }))
  }

  isLoggedInStatus() {
    return this.isLoggedIn
  }

  loginError() {
    return this.LoginStatus.asObservable();
  }

  public forgotPassword() {

  }

  public resetPassword() {

  }

  public logout() {
    this.removeFromStorage('access_token');
    this.removeFromStorage('refresh_token');
    this.router.navigate(['/login']);
  }

  getAccessToken() {
    return localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
  }


  public getWhatsapp() {
    return this.http.get(`${environment.apiURL}/whatsapp/`)
      .pipe(map((whatsapp) => {
        return whatsapp;
      }))
  }
}
