import { Component, OnInit, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public bannerData: any = [];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Output() showBan : boolean = true;
  public showError: boolean = false;
  public showErrorMsg: string = '';

  public loginForm: FormGroup;

  constructor(
    private authService:AuthService,
    private fb:FormBuilder,
    private router:Router,
    private bannerService:BannerServiceService
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    if(this.show) {
      this.showBanner = false;
    }
    this.bannerService.getBanners('Login')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })
    this.initializeForm()
  }


  public initializeForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  public login() {
      this.authService.deltaLogin(this.loginForm.get('email').value, this.loginForm.get('password').value)
        .subscribe({
          next: (loginData: any) => {
            if(loginData?.message == 'Logged In Successfully') {
              this.authService.getToken(this.loginForm.get('email').value, this.loginForm.get('password').value);
              this.authService.LoginStatus.next(true);
              this.router.navigate(['profile']);

              this.showError = false;
            }else {
              this.showError = true;
              this.showErrorMsg = 'Your email or password is incorrect!'
            }
          }
        })
  }


  public forgotPassword() {
    this.router.navigate(['reset-password'])
  }

}
