import { Component, OnInit, Input, Output } from '@angular/core';
import { AfghanistanService } from '../../services/afghanistan.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';

@Component({
  selector: 'app-national-zoo',
  templateUrl: './national-zoo.component.html',
  styleUrls: ['./national-zoo.component.css']
})
export class NationalZooComponent implements OnInit {
  public bannerData: any = [];
  public nationalZooData: any=[];
  @Input() show: boolean  = true;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  public searchText;

  constructor(
    private service: AfghanistanService,
    private bannerService: BannerServiceService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {

    this.bannerService.getBanners('Afg_NationalZoo')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;

        }
      })
    this.getNationalZoo();
  }

  public getNationalZoo(){
    this.service.getNationalZoo()
    .subscribe({
      next: (response : any) => {
        console.log('national_zoo ', JSON.stringify(response));

        this.nationalZooData = response?.national_zoo?.results;
        this.nextLink = response?.national_zoo?.next;
        console.log('nextLink ', this.nextLink);


      },
      error: (err) => {
      },

    })

  }

  search() {
    this.commonService.search(this.searchText, 'afghanistan', 'national-zoo')
    .subscribe({
      next: (result) => {
        this.nationalZooData = result['national_zoo']?.results;
      }
    })
}

  public next() {
    if (this.nextLink != null) {
      this.commonService.getNextPage(this.nextLink)
        .subscribe({
          next: (response: any) => {
            this.nationalZooData = response?.national_zoo?.results;
            if (this.nextLink) {
              let pageNumber;
              pageNumber = this.nextLink.match("=");
              this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
              this.lastPageNumber = this.pageNumber;
              this.previousLink = response?.national_zoo?.previous;
              this.nextLink = response?.national_zoo?.next;
            } else {
              this.pageNumber = this.lastPageNumber + 1;
            }
            this.previousBtnDisable = false
          }
        })
    }
  }


  public previous() {
    if (this.previousLink != null) {
      this.commonService.getPreviousPage(this.previousLink)
        .subscribe({
          next: (response: any) => {
            if (this.previousLink == null) {
              this.nextBtnDisable = false;
            } else {
              this.nextBtnDisable = true;
              if (this.previousLink) {
                let pageNumber;
              pageNumber = this.previousLink.match("=");
              if(pageNumber == null) {
                this.pageNumber = 1;
              }else {
                this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
              }

                this.nextLink = response?.national_zoo?.next;
                this.previousLink = response?.national_zoo?.previous;
                this.nationalZooData = response?.national_zoo?.results;
              }
            }
          }
        })
    }else {
      this.pageNumber = 1;
    }
  }

  toTop() {
    window.scrollTo(0, 0)
  }
}
