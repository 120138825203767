import { Component, OnInit, Input, Output } from '@angular/core';
import { ServiceService } from '../../services/service.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HotelBookingComponent } from '../../modals/hotel-booking/hotel-booking.component';

@Component({
  selector: 'app-hotel-parking',
  templateUrl: './hotel-parking.component.html',
  styleUrls: ['./hotel-parking.component.css']
})
export class HotelParkingComponent implements OnInit {
  public bannerData: any = [];
  public hotelParkingsData: any=[];
  @Input() show: boolean  = true;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  public deleteModelRef: NgbModalRef;
  public searchText;

  constructor(
    private service: ServiceService,
    private bannerService: BannerServiceService,
    private commonService: CommonService,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.bannerService.getBanners('Service_HotelParking')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;

        }
      })
    this.gethotelParking();
  }

  public gethotelParking(){
    this.service.getHotelParking()
    .subscribe({
      next: (response : any) => {
        this.hotelParkingsData = response?.hotel_parkings?.results;
        this.nextLink = response?.hotel_parkings.next;
      },

      error: (err) => {

      },

    })

  }

  search() {
    this.commonService.search(this.searchText, 'services', 'hotel-parking')
    .subscribe({
      next: (result) => {
        this.hotelParkingsData = result['hotel_parkings']?.results;
      }
    })
}

public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.hotelParkingsData = response?.hotel_parkings?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.hotel_parkings?.previous;
            this.nextLink = response?.hotel_parkings?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.hotel_parkings?.next;
              this.previousLink = response?.hotel_parkings?.previous;
              this.hotelParkingsData = response?.hotel_parkings?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}

  public hotelBooking() {
    this.deleteModelRef = this.modal.open(HotelBookingComponent, { keyboard: false, size: 'lg',  backdrop: false, backdropClass: "zIndex"});
    this.deleteModelRef.closed.subscribe(value => {
      if (value) {
        this.service.createRequest(value)
          .subscribe(hotelBooking => {
            this.ngOnInit();
          })
      }
    })

  }
  toTop() {
    window.scrollTo(0, 0)
  }
}
