import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.css']
})
export class InsuranceDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
