import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-translator-details',
  templateUrl: './translator-details.component.html',
  styleUrls: ['./translator-details.component.css']
})
export class TranslatorDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
