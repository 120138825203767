import { Component, OnInit, Input, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  public bannerData: any = [];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Output() showBan : boolean = true;
  submitted = false;
  showError: boolean = false;
  errorMsg: string;

  public registerForm!: FormGroup;

  constructor(
    private authService:AuthService,
    private fb:FormBuilder,
    private router: Router,
    private bannerService:BannerServiceService
  ) { }

  ngOnInit(): void {
    if(this.show) {
      this.showBanner = false;
    }
    this.bannerService.getBanners('SignUp')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })

    this.initializeForm();
  }

  public initializeForm() {
    this.registerForm = this.fb.group({
      first_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s,.'-]+$/)]],
      last_name: [''],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[!@#$%^&*()_+-=])(?=.*[A-Z]).*$/)
      ]],
      confirm_password: ['', Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
  }

  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
  }


  register() {
      this.showError = false;
      if(this.registerForm.valid){
        if(this.registerForm.get('password').value == this.registerForm.get('confirm_password').value)
        {
        this.authService.register(this.registerForm.value)
          .subscribe((registered) => {
            if(registered) {
              this.router.navigate(['/login']);
            }
          })
      }else{
        this.showError = true;
        this.errorMsg = 'Passwords do not match'
      }

      }else{
        this.showError = true
        this.errorMsg = "Invalid Form, Kindly fill the Form Accordingly"
      }


  }

}
