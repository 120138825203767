import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerServiceService {

  constructor(private http:HttpClient) { }

  public getBanners(componentName: string) {
    return this.http.get(`${environment.apiURL}/banner/${componentName}`)
      .pipe(map((image) => {
        return image;
      }))
  }
}
