import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AfghanistanService {

  constructor(private http:HttpClient) { }

  getHistory() {
    return this.http.get(`${environment.apiURL}/afghanistan/history/`)
      .pipe(map((history) => {
        return history;
      }))
  }

  getCultureTradition(){
    return this.http.get(`${environment.apiURL}/afghanistan/culture-tradition/`)
    .pipe(map((culture)=>{
      return culture;
    }))
  }

  getHistoricPlaces(){
    return this.http.get(`${environment.apiURL}/afghanistan/historic-place/`)
    .pipe(map((historicplaces)=>{
     return historicplaces;
    }))
  }

  getNationalParks(){
    return this.http.get(`${environment.apiURL}/afghanistan/national-park/`)
    .pipe(map((nationalparks)=>{
     return nationalparks;
    }))
  }

  getNationalMuseums(){
    return this.http.get(`${environment.apiURL}/afghanistan/national-museum/`)
    .pipe(map((nationalmuseums)=>{
     return nationalmuseums;
    }))
  }

  getNationalZoo(){
    return this.http.get(`${environment.apiURL}/afghanistan/national-zoo/`)
    .pipe(map((nationalzoo)=>{
      return nationalzoo;
    }))
  }

  getAgriculture(){
    return this.http.get(`${environment.apiURL}/afghanistan/agriculture/`)
    .pipe(map((agriculture)=>{
      return agriculture;
    }))
  }

  getWaterDemsCanals(){
    return this.http.get(`${environment.apiURL}/afghanistan/dam-canal/`)
    .pipe(map((waterdemscanals)=>{
      return waterdemscanals;
    }))
  }

  getNationalHotels(){
    return this.http.get(`${environment.apiURL}/afghanistan/national-hotel/`)
    .pipe(map((nationalhotels)=>{
      return nationalhotels;
    }))
  }

  getTransportation(){
    return this.http.get(`${environment.apiURL}/afghanistan/transportation/`)
    .pipe(map((transportation)=>{
      return transportation;
    }))
  }

}
