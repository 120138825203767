import { Component, OnInit, Input, Output } from '@angular/core';
import { ServiceService } from '../../services/service.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {
  public bannerData: any = [];
  public packagesData: any=[];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Input() limit: number = 100;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;


  constructor(
    private service: ServiceService,
    private bannerService: BannerServiceService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    if(this.show) {
      this.showBanner = false;
    }


    this.bannerService.getBanners('Service_Packages')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;

        }
      })
    this.getPackages();
  }

  public getPackages(){
    this.service.getPackages()
    .subscribe({
      next: (response : any) => {
        if(this.limit == 100) {
          this.packagesData = response?.packages?.results;
        }else if(this.limit != 100) {
          for(let i =1; i <=3; i++) {
            this.packagesData.push(response?.packages?.results[i]);
          }
        }
      },

      error: (err) => {

      },

    })

  }

  public next() {
    if (this.nextLink != null) {
      this.commonService.getNextPage(this.nextLink)
        .subscribe({
          next: (response: any) => {
            this.packagesData = response?.packages?.results;
            this.nextLink = response?.packages?.next;
            this.previousLink = response?.packages?.previous;


            if (this.nextLink) {
              let pageNumber;

              pageNumber = this.nextLink.match("=");
              this.pageNumber = Number(this.nextLink[pageNumber?.index + 1] - 1);
              this.lastPageNumber = this.pageNumber;

            } else {
              this.pageNumber = this.lastPageNumber + 1;

            }
            this.previousBtnDisable = false
          }
        })
    }
  }


  public previous() {
    if (this.previousLink != null) {

      this.commonService.getPreviousPage(this.previousLink)
        .subscribe({
          next: (response: any) => {
            this.packagesData = response?.packages?.results;
            this.nextLink = response?.packages?.next;
            this.previousLink = response?.packages?.previous;
            if (response?.packages?.previous != null) {
              this.nextBtnDisable = false;

              if (this.previousLink) {
                let pageNumber;

                pageNumber = this.previousLink.match("=");

                if (pageNumber == null) {
                  this.pageNumber = 1;
                } else {


                  this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
                  this.pageNumber += 1;

                }

              }
            } else {
              this.nextBtnDisable = true;
            }
          }
        })
    }
  }


  toTop() {
    window.scrollTo(0, 0)
  }
}
