import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-online-payment-modal',
  templateUrl: './online-payment-modal.component.html',
  styleUrls: ['./online-payment-modal.component.css'],
})
export class OnlinePaymentModalComponent implements OnInit {

  constructor(private modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public close() {
    this.modal.dismiss();
  }
}
