import { Component, OnInit, Input, Output } from '@angular/core';
import { OpportunityService } from '../../services/opportunity.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.css']
})
export class VacanciesComponent implements OnInit {
  public bannerData: any = [];
  public vacanciesData: any=[];
  public showBanner = true;
  public isReadMore = true;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  @Input() show: boolean  = true;
  @Output() showBan : boolean = true;
  public searchText;

  constructor(
    private service: OpportunityService,
    private bannerService: BannerServiceService,
    private commonService: CommonService

  ) { }

  ngOnInit(): void {
    if(this.show) {
      this.showBanner = false;
    }
    this.bannerService.getBanners('Oppo_Vacancies')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })
    this.getVacancies();
  }
  showText(id: number, elementRef: HTMLElement){
    elementRef.style.overflow = 'visible';
    elementRef.style.height = 'auto'
  }
  showLess(id: number, elementRef: HTMLElement){
    elementRef.style.visibility = 'visible';
    elementRef.style.height = '490px';
    elementRef.style.overflow= 'hidden';
  }
  public getVacancies(){
    this.service.getVacancies()
    .subscribe({
      next: (response : any) => {
        this.vacanciesData = response?.vacancies?.results;
        this.nextLink = response?.vacancies?.next;

      },
      error: (err) => {
      },

    })

  }
  search() {
    this.commonService.search(this.searchText, 'opportunity', 'vacancy')
    .subscribe({
      next: (result) => {
        this.vacanciesData = result['vacancies']?.results;
      }
    })
}

public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.vacanciesData = response?.vacancies?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.vacancies?.previous;
            this.nextLink = response?.vacancies?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.vacancies?.next;
              this.previousLink = response?.vacancies?.previous;
              this.vacanciesData = response?.vacancies?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}

  toTop() {
    window.scrollTo(0, 0)
  }
}
