import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

import { distinctUntilChanged, map, startWith, take } from 'rxjs/operators';
import { ExchangeRatesApiRequestService } from '../../../shared/service/exchange-rates-api-request.service';
import { AlertService } from '../../../core/alert/alert.service';
import { CurrencyExchangeService, PeriodicHistoryElement } from '../../../shared/service/currency-exchange.service';
import {
    ExchangeRatesResponse,
    MappedCurrencyRateObject,
    Statistics,
} from '../../../shared/exchange-rates.model';

import { StorageService } from '../../../shared/service/storage.service';

import {
    Currency,
    FormNames,
    LocalStorageItems,
    StatisticalDataTableFields,
    TableColumnNames,
    TimeIntervalTypes,
} from '../../../shared/enums.model';
import getSymbolFromCurrency from 'currency-symbol-map';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CurrencyService } from '../../services/currency.service';

@Component({
    selector: 'app-converter',
    templateUrl: './converter.component.html',
    styleUrls: ['./converter.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ConverterComponent implements OnInit {
  public currencyForm: FormGroup;
  public toCurrency;
  public result;
  public fromCurrency;
  public amount;
  public selectedCurrencyTo;
  public currencyToData;
  public selectedCurrencyFrom;
  public currencyFromData;

    constructor(
        public currencyExchangeService: CurrencyExchangeService,
        private fb:FormBuilder,
        private currencyService:CurrencyService
    ) {

    }

    ngOnInit() {
      this.initializeForm();
      this.currencyService.getCurrenciesSymbols()
        .subscribe({
          next: (symbol: any) => {
            console.log('symbols are ', JSON.stringify(symbol));
            this.currencyFromData = Object.entries(symbol?.symbols).map(([key,value]) => ({key, value}));
            this.currencyToData = Object.entries(symbol?.symbols).map(([key,value]) => ({key, value}));
            console.log('getCurrencies called');


          }
        })
    }


    initializeForm() {
      this.currencyForm = this.fb.group({
        fromControl: [],
        toControl: [],
        amountControl: []
      })
    }


    changeExchangeInputValues(){

    }


    public convertCurrency() {
      console.log('amount is ', this.currencyForm.get('amountControl').value);
      console.log('from is ', this.selectedCurrencyFrom);
      console.log('to is ', this.selectedCurrencyTo);

      this.currencyService.convertCurrencies(this.currencyForm.get('amountControl').value, this.selectedCurrencyFrom, this.selectedCurrencyTo)
        .subscribe({
          next: (result: any) => {
            console.log('result is ', JSON.stringify(result));

          }
        })
    }




}
