import { Component, OnInit, Input, Output } from '@angular/core';
import { AfghanistanService } from '../../services/afghanistan.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';

@Component({
  selector: 'app-water-dems-canals',
  templateUrl: './water-dems-canals.component.html',
  styleUrls: ['./water-dems-canals.component.css']
})
export class WaterDemsCanalsComponent implements OnInit {
  public bannerData: any = [];
  public waterDemCanalsData: any=[];
  public showBanner = true;
  @Input() show: boolean  = true;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  public searchText;

  constructor(
    private service : AfghanistanService,
    private bannerService: BannerServiceService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.bannerService.getBanners('Afg_WaterDamCanals')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })
    this.getWaterDemCanals();

  }
  public getWaterDemCanals(){
    this.service.getWaterDemsCanals()
    .subscribe({
      next: (response : any) => {
        this.waterDemCanalsData = response?.water_dam_canals?.results;
        this.nextLink = response?.water_dam_canals.next;
      },
      error: (err) => {
      },

    })

  }

  search() {
    this.commonService.search(this.searchText, 'afghanistan', 'dam-canal')
    .subscribe({
      next: (result) => {
        this.waterDemCanalsData = result['water_dam_canals']?.results;
      }
    })
}

public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.waterDemCanalsData = response?.water_dam_canals?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.water_dam_canals?.previous;
            this.nextLink = response?.water_dam_canals?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.water_dam_canals?.next;
              this.previousLink = response?.water_dam_canals?.previous;
              this.waterDemCanalsData = response?.water_dam_canals?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}
  toTop() {
    window.scrollTo(0, 0)
  }
}
