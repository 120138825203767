import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {

  constructor(private http:HttpClient) { }

  getAboutUs(){
    return this.http.get(`${environment.apiURL}/about-us/`)
    .pipe(map((aboutUS)=>{
      return aboutUS;
    }))
  }
  getAddress(){
    return this.http.get(`${environment.apiURL}/about-us/address`)
    .pipe(map((address)=>{
      return address;
    }))
  }
  getTeams(){
    return this.http.get(`${environment.apiURL}/about-us/teams`)
    .pipe(map((teams)=>{
      return teams;
    }))
  }

  getAriaDeltaSocial(){
    return this.http.get(`${environment.apiURL}/whatsapp/`)
    .pipe(map((ariadelta)=>{
      return ariadelta
    }))
  }
}
