
<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header primary-bg mb-3">
        <h4 class="text-center" style="color: black;">{{"Share On Social Media"}}</h4>
      </div>
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-9">
          <div class="card-body text-center">

            <ul class="d-flex flex-row ms-n3 nav">
              <li class="nav-item">
                  <i class="fab fa-facebook text-lg opacity-8 nav-link pe-1" style="color: #0165E1;"
                  (click)="shareOnFacebook()"></i>
              </li>
              <li class="nav-item">
                  <i (click)="shareOnTwitter()" class="fab fa-twitter text-lg opacity-8 nav-link pe-1" style="color: #08a0e9;"></i>
              </li>
              <li class="nav-item">
                  <i (click)="shareOnWhatsApp()" class="fab fa-whatsapp text-lg opacity-8 nav-link pe-1" style="color: #25D366;"></i>
              </li>
              <li class="nav-item">
                  <i (click)="(shareOnLinkedIn())" class="fab fa-linkedin text-lg opacity-8 nav-link pe-1" style="color: #0A66C2;"></i>
              </li>
              <li class="nav-item">
                  <i (click)="shareOnInstagram()" class="fab fa-instagram text-lg opacity-8 nav-link pe-1" style="color: #0A66C2;"></i>
              </li>
            </ul>


        </div>
        </div>
      </div>

    </div>
  </div>
</div>
