import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private http:HttpClient) { }



  public getCurrenciesSymbols() {
    return this.http.get('http://data.fixer.io/api/symbols?access_key=5384e82d6f8818963d2c13b43b08c3f4')
      .pipe(map((symbols) => {
        return symbols;
      }))

  }

  public convertCurrencies(amount: number, currencyFrom: string, currencyTo: string) {
    return this.http.get('http://data.fixer.io/api/convert?access_key=5384e82d6f8818963d2c13b43b08c3f4&from='+ currencyFrom + '&to='+ currencyTo + '&amount='+amount)
      .pipe(map((conversion) => {
        return conversion;
      }))
  }
}
