import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateConverterService } from 'src/app/shared/service/date-converter-services/date-converter.service';
// import * as moment from 'jalali-moment';

@Component({
  selector: 'app-date-converter',
  templateUrl: './date-converter.component.html',
  styleUrls: ['./date-converter.component.css']
})
export class DateConverterComponent implements OnInit {

  public dateConverterForm: FormGroup;
  public gregorianDate;
  public persianDate;
  public show: boolean = false;
  constructor(
    private dateConverterService:DateConverterService,
    private fb:FormBuilder
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }


  public initializeForm() {
    this.dateConverterForm = this.fb.group({
      gregorian: ['', Validators.required],
      shamsi: ['', Validators.required]
    })
  }



  convertToGregorian(date) {
    new Date(date).toLocaleDateString('en');
  }

  convertToPersian(date) {
    this.dateConverterService.toPersian(date);
  }

  convert(){
    if(this.dateConverterForm.get('gregorian').value != null) {
      this.persianDate = new Date(this.dateConverterForm.get('gregorian').value).toLocaleDateString('fa-IR');
      this.gregorianDate = this.dateConverterForm.get('gregorian').value;
    }else if(this.dateConverterForm.get('shamsi').value != null) {
      this.gregorianDate = new Date(this.dateConverterForm.get('shamsi').value).toLocaleDateString('en-EN');
      this.persianDate = this.dateConverterForm.get('shamis').value
    }
    if(this.show) {
      this.show = false;
    }else {
      this.show = true;
    }
  }
}
