import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http:HttpClient) { }

  getFlights() {
    return this.http.get(`${environment.apiURL}/services/flight/`)
      .pipe(map((flight)=>{
      return flight;
      }))
  }

  getPackages(){
    return this.http.get(`${environment.apiURL}/services/package/`)
    .pipe(map((packages)=>{
     return packages;
    }))
  }

  getInsurance(){
    return this.http.get(`${environment.apiURL}/services/insurance/`)
    .pipe(map((insurance)=>{
     return insurance;
    }))
  }

  getVisaAssistance(){
    return this.http.get(`${environment.apiURL}/services/visa-assistance/`)
    .pipe(map((visaAssistance)=>{
     return visaAssistance;
    }))
  }

  getTransportation(){
    return this.http.get(`${environment.apiURL}/services/transportation/`)
    .pipe(map((transportation)=>{
     return transportation;
    }))
  }

  getHotelParking(){
    return this.http.get(`${environment.apiURL}/services/hotel-parking/`)
    .pipe(map((hotelParking)=>{
      return hotelParking;
    }))
  }

  getBookingScholorships(){
    return this.http.get(`${environment.apiURL}/services/booking-scholorship/`)
    .pipe(map((bookingScholorship)=>{
      return bookingScholorship;
    }))
  }

  getTranslators(){
    return this.http.get(`${environment.apiURL}/services/translator/`)
    .pipe(map((translator)=>{
      return translator;
    }))
  }
  getCarRental(){
    return this.http.get(`${environment.apiURL}/services/car-rental/`)
    .pipe(map((carRental)=>{
      return carRental;
    }))
  }



  public createRequest(data: any) {
    return this.http.post(`${environment.apiURL}/services/request/`, data)
      .pipe(map((registered) => {
        return registered
      }))
  }


}
