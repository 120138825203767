import { Component, OnInit, Input, Output } from '@angular/core';
import { AfghanistanService } from '../../services/afghanistan.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';
@Component({
  selector: 'app-culture-tradition',
  templateUrl: './culture-tradition.component.html',
  styleUrls: ['./culture-tradition.component.css']
})
export class CultureTraditionComponent implements OnInit {
  public bannerData: any = [];
  public cultureTraditionData: any=[];
  public showBanner = true;
  @Input() show: boolean  = true;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  public searchText;

  constructor(
    private service:AfghanistanService,
    private bannerservice: BannerServiceService,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {
    if(this.show) {
      this.showBanner = false;
    }
    this.bannerservice.getBanners('Afg_CultureTradition')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })
    this.getCultureTradition();
  }

  public getCultureTradition(){
    this.service.getCultureTradition()
    .subscribe({
      next: (response : any) => {
        this.cultureTraditionData = response?.culture_traditions?.results;
        this.nextLink = response?.culture_traditions.next;
      },
      error: (err) => {

      },

    })

  }

  search() {
    this.commonService.search(this.searchText, 'afghanistan', 'culture-tradition')
    .subscribe({
      next: (result) => {
        this.cultureTraditionData = result['culture_traditions']?.results;
      }
    })
}

public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.cultureTraditionData = response?.culture_traditions?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.culture_traditions?.previous;
            this.nextLink = response?.culture_traditions?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.culture_traditions?.next;
              this.previousLink = response?.culture_traditions?.previous;
              this.cultureTraditionData = response?.culture_traditions?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}
  toTop() {
    window.scrollTo(0, 0)
  }
}
