import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-announcement-details',
  templateUrl: './announcement-details.component.html',
  styleUrls: ['./announcement-details.component.css']
})
export class AnnouncementDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
