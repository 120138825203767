
<div class="card">
  <div class="card-header primary-bg">
    <h3 class="text-center" style="color: white;">Calendar</h3>
  </div>
  <div class="card-body mt-4">
    <dl-date-time-picker
    startView="day"
    maxView="year"
    minView="minute"
    [(ngModel)]="selected"
>
</dl-date-time-picker>
  </div>
</div>



