import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wdc-details',
  templateUrl: './wdc-details.component.html',
  styleUrls: ['./wdc-details.component.css']
})
export class WdcDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
