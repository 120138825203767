import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  public bannerData: any = [];
  public showBanner = true;
  public showError: boolean = false;
  public showErrorMsg: string = '';
  public resetForm:FormGroup;


  constructor(
    private authService:AuthService,
    private fb:FormBuilder
    ) { }

  ngOnInit(): void {
    this.initializeForm();
  }


  public initializeForm() {
    this.resetForm = this.fb.group({
      password: ['', Validators.required],
      confirm: ['', Validators.required]

    })
  }

  public reset() {

  }

}
