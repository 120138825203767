import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpportunitiesRoutingModule } from './opportunities-routing.module';
import { OpportunitiesComponent } from './opportunities.component';
import { VacanciesComponent } from './components/vacancies/vacancies.component';
import { TenderComponent } from './components/tender/tender.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceRoutingModule } from '../service/service-routing.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AnnouncementDetailsComponent } from './components/announcements/announcement-details/announcement-details.component';
import { TenderDetailsComponent } from './components/tender/tender-details/tender-details.component';
import { VacancyDetailsComponent } from './components/vacancies/vacancy-details/vacancy-details.component';


@NgModule({
  declarations: [
    OpportunitiesComponent,
    VacanciesComponent,
    TenderComponent,
    AnnouncementsComponent,
    AnnouncementDetailsComponent,
    TenderDetailsComponent,
    VacancyDetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ServiceRoutingModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    OpportunitiesRoutingModule,
    HttpClientModule
  ],
  exports : [AnnouncementsComponent]
})
export class OpportunitiesModule { }
