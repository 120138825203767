<div class="page-header align-items-start min-height-300 m-3 border-radius-xl bg-gray-200"
  [ngStyle]="{'background-image' : 'url('+ bannerData?.image + ')'}" loading="lazy">
  <span class="bg-gradient-dark opacity-4"></span>
</div>

<div class="container">
  <div class="row mt-lg-n12 mt-md-n12 mt-n11 justify-content-center">
    <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
      <div class="card mt-8">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div class="primary-bg shadow-primary border-radius-lg py-3 pe-1 text-center py-4">
            <h4 class="font-weight-bolder text-white mt-1">Register with Aria Delta Travel & Tours</h4>
            <p class="mb-1 text-white text-sm">Enter your email and password to register</p>
          </div>
        </div>
        <div class="card-body pb-3">
          <form role="form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="text-danger text-center" *ngIf="showError">
              <span class="text-danger">{{errorMsg}}</span>
            </div>
            <div class="input-group input-group-outline mb-3">
              <input type="text" class="form-control" formControlName="first_name" placeholder="Enter First Name"
                [ngClass]="{ 'is-invalid': submitted && f['first_name'].errors }">
              <div *ngIf="submitted && f['first_name'].errors" class="invalid-feedback">
                <div style="margin-top: -10px;" class="" *ngIf="f['first_name'].errors['required']">First Name is
                  required</div>
                <div style="margin-top: -10px;" class="" *ngIf="f['first_name'].errors['pattern']">Name can not contain
                  numbers</div>
              </div>
            </div>
            <div class="input-group input-group-outline mb-3">
              <input type="text" class="form-control" formControlName="last_name" placeholder="Enter Last Name">
            </div>
            <div class="input-group input-group-outline mb-3">
              <input type="email" class="form-control" formControlName="email" placeholder="Enter Email Address"
                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
              <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                <div style="margin-top: -10px;" class="" *ngIf="f['email'].errors['required']">Email is required</div>
                <div style="margin-top: -10px;" class="" *ngIf="f['email'].errors['email']">Email is invalid</div>
              </div>
            </div>
            <div class="input-group input-group-outline mb-3">
              <input type="password" class="form-control radius-30 ps-5" id="inputChoosePassword"
                placeholder="Enter Password" formControlName="password"
                [ngClass]="{'is-invalid':submitted && f['password'].errors}">
              <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                <div style="margin-top: -10px;" class="" *ngIf="f['password'].errors['required']">Password is Required</div>
                <!-- <div class="position-absolute"   *ngIf="f['password'].errors['minlength']">Password must contain at least 8 characters</div> -->
                <div style="margin-top: -10px;" class=""
                  *ngIf="f['password'].errors['pattern'] || f['password'].errors['minlength'] "> 8 chars, 1 special char, 1 uppercase char
                  <br>
                </div>
              </div>
            </div>
            <div class="input-group input-group-outline mb-3">
              <input type="password" class="form-control radius-30 ps-5" id="inputChoosePasswordConfirm"
                placeholder="Confirm Password" formControlName="confirm_password"
                [ngClass]="{'is-invalid':submitted && f['confirm_password'].errors}">
              <div *ngIf="submitted && f['confirm_password'].errors" class="invalid-feedback">
                <div style="margin-top: -10px;" class="" *ngIf="f['confirm_password'].errors['required']">Confirm Password</div>
              </div>
            </div>
            <div class="form-check text-left">
              <input class="form-check-input bg-dark border-dark" type="checkbox" value="" id="flexCheckDefault"
                checked="">
              <label class="form-check-label" for="flexCheckDefault">
                I agree the <a [routerLink]="['/terms-conditions']" class="text-dark font-weight-bolder">Terms and
                  Conditions</a>
              </label>
            </div>
            <div class="text-center">
              <button type="submit" class="btn primary-bg text-white w-100 mt-4 mb-0" (click)="register()">Sign
                up</button>
            </div>
          </form>
        </div>
        <div class="card-footer text-center pt-0 px-sm-4 px-1">
          <p class="mb-4 mx-auto">
            Already have an account?
            <a [routerLink]="['/login']" class="move-on-hover font-weight-bold">Sign
              in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
