<footer class="footer pt-5 mt-1">
  <div class="container">
    <div class=" row">
      <div class="col-md-3 col-sm-6 col-6 mb-0 mt-0">
        <div>
          <a class="navbar-brand font-weight-bolder ms-sm-3"
          rel="tooltip"  data-placement="bottom" target="_blank"
          style="margin-left: 50px; font-size: 18px;">
            <!-- <img src="../../assets/travel-logo-last-.png3.png" alt="" width="120" height="100" > -->
            <img [src]="bannerData?.image" alt="" width="120" height="100" >
        </a>
        <h6 class="font-weight-bolder mb-2">Follow Aria Delta Travel & Tours</h6>
        </div>
        <div>
          <div class="row">
            <div class="col-md-12 input-group">
              <ul class="d-flex flex-row ms-n1 nav">
                <li class="nav-item">
                  <a class="nav-link pe-1" href="{{facebook[0]?.url}}" target="_blank">
                    <i class="fab fa-facebook text-lg opacity-8" style="color: #0165E1;"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link pe-1" href="{{twitter[0]?.url}}"  target="_blank">
                    <i class="fab fa-twitter text-lg opacity-8" style="color: #08a0e9;"></i>
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link pe-1" href="{{whatsappdata}}"  target="_blank">
                    <i class="fab fa-whatsapp text-lg opacity-8" style="color: #25D366;"></i>
                  </a>
                </li> -->
                <li class="nav-item">
                  <a class="nav-link pe-1" href="{{linkedin[0]?.url}}"  target="_blank">
                    <i class="fab fa-linkedin text-lg opacity-8" style="color: #0A66C2;"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link pe-1" href="{{instagram[0]?.url}}"  target="_blank">
                    <i class="fab fa-instagram text-lg opacity-8" style="color: #405DE6;"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link pe-1" href="{{tiktok[0]?.url}}"  target="_blank">
                    <i class="fab fa-tiktok text-lg opacity-8" style="color: #000000;"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-6 mb-4">
          <h5 class="text-md text-warning">Company</h5>
          <ul class="flex-column ms-n3 nav">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/about_us']" target="_blank">
                About Us
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="history" target="_blank">
                History
              </a>
            </li>
          </ul>
      </div>
      <div class="col-md-2 col-sm-6 col-6 mb-4">
        <div>
          <h5 class="text-md text-warning">Services</h5>
          <ul class="flex-column ms-n3 nav">
            <li class="nav-item">
              <a class="nav-link" routerLink="service/booking-scholorships" target="_blank">
                Scholorships
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="service/visa-assistance" target="_blank">
                Visa Assistance
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="service/flights" target="_blank">
                Flight Packages
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="service/transportation" target="_blank">
                Airport Taxis
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="service/car-rental" target="_blank">
                Car Rentals
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6 mb-4 me-auto">
        <div>
          <h5 class="text-md text-warning">Legal</h5>
          <ul class="flex-column ms-n3 nav">
            <li class="nav-item">
              <a class="nav-link" routerLink="terms-conditions" target="_blank">
                Travel & Tours Terms & Condition
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="faq" target="_blank">
                FAQ
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-6 mb-4">
        <div>
          <h5 class="text-md text-warning">Contact Address</h5>
          <ul class="flex-column ms-n3 nav" *ngFor="let item of addressData">
            <li class="nav-item" >
              <a class="nav-link" target="_blank" rel="tooltip" title="{{item?.description}}">
                {{item?.location}}
              </a>
            </li>

          </ul>
        </div>
      </div>
      <hr>
      <div class="col-md-1 col-sm-6 col-6 mb-2 me-auto">
        <!-- <a [href]="whatsapp"><img src="assets/whatsapp.png" alt="" width="40" height="40"></a> -->
        <div class="widget-preview" (click)="toggleClass()">
          <div class="messenger">
            <div id="messenger__block" class="messenger__block" [ngClass]="{'messenger-show': isToggled}">


              <a rel="nofollow" target="_blank" href="{{whatsappdata}}"
                class="messenger__btn messenger__btn_telegram">
                <div class="messenger__btn-caption"> Messenger</div>
              </a>

              <!-- <a rel="nofollow" target="_blank" href="https://{{instagram[0]?.url}}"
                class="messenger__btn messenger__btn_skype">
                <div class="messenger__btn-caption">Messenger</div>
              </a> -->

              <a rel="nofollow" target="_blank" href="{{messanger[0]?.url}}"
                class="messenger__btn messenger__btn_instagram">
                <div class="messenger__btn-caption">Messenger</div>
              </a>

              <a rel="nofollow" target="_blank" href="{{telegram[0]?.url}}"
              class="messenger__btn messenger__btn_vkontakte">
              <div class="messenger__btn-caption">Telegram</div>
            </a>

              <div class="messenger_owner">
                <p>Powered by <a href="http://az-dev.tech" _target="blank">AZ-DEV</a></p>
              </div>
            </div>
            <div class="messenger__switch messenger_switch-toggle" style="background-color:#f1c073;">
              <div id="messenger__static" class="messenger__static"><svg xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" class="messenger__static-icon" width="20" height="20"
                  viewBox="0 0 20 20" version="1.1">
                  <g id="Canvas" transform="translate(-825 -308)">
                    <g id="Vector">
                      <use xlink:href="#path0_fill" transform="translate(825 308)" fill="#FFFFFF"></use>
                    </g>
                  </g>
                  <defs>
                    <path id="path0_fill"
                      d="M 19 4L 17 4L 17 13L 4 13L 4 15C 4 15.55 4.45 16 5 16L 16 16L 20 20L 20 5C 20 4.45 19.55 4 19 4ZM 15 10L 15 1C 15 0.45 14.55 0 14 0L 1 0C 0.45 0 0 0.45 0 1L 0 15L 4 11L 14 11C 14.55 11 15 10.55 15 10Z">
                    </path>
                  </defs>
                </svg>
                <div class="messenger__static-caption" style="color: #FFFFFF;">Support</div>
              </div>
              <div id="messenger__close" class="messenger__close"><svg class="messenger__close-icon">
                  <g id="Canvas" transform="translate(-829 -1155)">
                    <g id="Shape">
                      <use xlink:href="#path1_fill" transform="matrix(0.707107 0.707107 -0.707107 0.707107 835 1151.52)"
                        fill="#FFFFFF"></use>
                    </g>
                  </g>
                  <defs>
                    <path id="path1_fill" fill-rule="evenodd"
                      d="M 14 6L 8 6L 8 0L 6 0L 6 6L 0 6L 0 8L 6 8L 6 14L 8 14L 8 8L 14 8L 14 6Z"></path>
                  </defs>
                </svg></div>
              <div class="messenger__pulsation"></div>
              <div class="messenger__pulsation"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 px-3">
          <img src="assets/img/payment_images/paypal.png" alt="" width="100" height="100">
        </div>
        <div class="col-md-2">
          <img src="assets/img/payment_images/master_card.png" alt="" width="100" height="100">
        </div>
        <div class="col-md-2">
          <img src="assets/img/payment_images/visa.png" alt="" width="100" height="100">
        </div>
        <div class="col-md-2">
          <img src="assets/img/payment_images/google_pay.png" alt="" width="100" height="100">
        </div>
        <div class="col-md-2">
          <img src="assets/img/payment_images/apple_pay.png" alt="" width="100" height="100">
        </div>
        <div class="col-md-2">
          <img src="assets/img/payment_images/bitcoin.png" alt="" width="100" height="100">
        </div>

      </div>
      <hr>
      <div class="row">
        <div class="col-md-8">
          <div >
            <p class="text-dark my-3 text-sm font-weight-normal">
              Copyright ©
              <script>document.write(new Date().getFullYear())</script> 2022-2023 Ariadelta Travel and Tours. All rights
              reserved By <a href="https://ariadelta.af/index.php?front/page/home" target="_blank">Aria Delta Consulting Group</a>.
            </p>
          </div>
        </div>
        <div class="col-md-2  my-3 text-sm font-weight-normal move-on-hover" >
          <a style="cursor: pointer; color: #EDB25A;" href="https://www.xe.com" target="_blank" class="font-weight-bold">Check Daily Currency Rates</a>
        </div>
        <div class="col-md-1  my-3 text-sm font-weight-normal move-on-hover" >
          <a style="cursor: pointer; color: #EDB25A;" [routerLink]="['/login']" class="font-weight-bold">Sign-in</a>
        </div>
        <div class="col-md-1 my-3 text-sm font-weight-normal move-on-hover" >
          <a style="cursor: pointer; color: #EDB25A;" [routerLink]="['/sign-up']" class="font-weight-bold">Sign-Up</a>
        </div>
      </div>
    </div>
  </div>
</footer>
