import { Component, OnInit, Input, Output } from '@angular/core';
import { ServiceService } from '../../services/service.service';
import { BannerServiceService } from 'src/app/banner-service.service';
import { CommonService } from 'src/app/commonService/common.service';



@Component({
  selector: 'app-flights',
  templateUrl: './flights.component.html',
  styleUrls: ['./flights.component.css']
})
export class FlightsComponent implements OnInit {
  public bannerData: any = [];
  public flightsData: any=[];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Input() notShow: boolean  = false;
  @Input() limit: number = 100;
  public nextBtnDisable = false;
  public previousBtnDisable = true;
  public nextLink : any;
  public previousLink : any;
  public pageNumber: any  = 1;
  public lastPageNumber = 0;
  public searchText;

  constructor(
    private service:ServiceService,
    private bannerService: BannerServiceService,
    private commonService: CommonService

  ) { }

  ngOnInit(): void {

    this.bannerService.getBanners('Service_Flights')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;

        }
      })
    this.getFlights();
  }

  public getFlights(){
    this.service.getFlights()
    .subscribe({
      next: (response : any) => {
        if(this.limit == 100){
          this.flightsData = response?.flights?.results;
        }else if(this.limit != 100) {
          for(let i =1; i <=3; i++) {
            this.flightsData.push(response?.flights?.results[i]);
          }
        }
      },

      error: (err) => {

      },

    })

  }

  search() {
    this.commonService.search(this.searchText, 'services', 'flight')
    .subscribe({
      next: (result) => {
        this.flightsData = result['flights']?.results;
      }
    })
}


public next() {
  if (this.nextLink != null) {
    this.commonService.getNextPage(this.nextLink)
      .subscribe({
        next: (response: any) => {
          this.flightsData = response?.flights?.results;
          if (this.nextLink) {
            let pageNumber;
            pageNumber = this.nextLink.match("=");
            this.pageNumber = Number(this.nextLink[pageNumber?.index + 1]);
            this.lastPageNumber = this.pageNumber;
            this.previousLink = response?.flights?.previous;
            this.nextLink = response?.flights?.next;
          } else {
            this.pageNumber = this.lastPageNumber + 1;
          }
          this.previousBtnDisable = false
        }
      })
  }
}


public previous() {
  if (this.previousLink != null) {
    this.commonService.getPreviousPage(this.previousLink)
      .subscribe({
        next: (response: any) => {
          if (this.previousLink == null) {
            this.nextBtnDisable = false;
          } else {
            this.nextBtnDisable = true;
            if (this.previousLink) {
              let pageNumber;
            pageNumber = this.previousLink.match("=");
            if(pageNumber == null) {
              this.pageNumber = 1;
            }else {
              this.pageNumber = Number(this.previousLink[pageNumber?.index + 1]);
            }

              this.nextLink = response?.flights?.next;
              this.previousLink = response?.flights?.previous;
              this.flightsData = response?.flights?.results;
            }
          }
        }
      })
  }else {
    this.pageNumber = 1;
  }
}

toTop() {
  window.scrollTo(0, 0)
}

}
