import { Component, OnInit, Input, Output } from '@angular/core';
import { BannerServiceService } from '../banner-service.service';
import { CommonService } from '../commonService/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public homeData: any =[];
  public bannerData: any = [];
  public showBanner = true;
  @Input() show: boolean  = true;
  @Output() showBan : boolean = true;
  public searchText;
  public totalVisitors: any;
  public errorAlert : boolean = false;
  public errorMsg;
  public successAlert : boolean = false;
  public successMsg;

  public subscribeForm: FormGroup;

  constructor(
    private bannerService: BannerServiceService,
    private commonService: CommonService,
    private fb:FormBuilder,
    private router: Router
    ) { }

  ngOnInit(): void {

    this.commonService.getVisitors()
      .subscribe({
        next: (visitor: any) => {
          if(visitor) {
            if(visitor?.visitors?.count == 0) {
              this.commonService.createVisitor(1)
                .subscribe({
                  next: (visitor) => {
                    if(visitor) {
                      this.totalVisitors = 1;
                    }
                  }
                })
            }else {
              this.commonService.updateVisitors(Number(visitor?.visitors?.results[0]?.visit) +  1, visitor?.visitors?.results[0]?.id)
                .subscribe({
                  next: (visitors: any) => {
                    this.totalVisitors = visitors?.visitor.visit;
                  }
                })
            }
          }
        }
      })
    if(this.show) {
      this.showBanner = false;
    }
    this.bannerService.getBanners('Home')
      .subscribe({
        next: (image: any) => {
          this.bannerData = image?.banner;
        }
      })

      this.initializeForm();
  }

  public initializeForm() {
    this.subscribeForm = this.fb.group({
     email: ['']
    });
   }


   public subscribe() {
    if(this.subscribeForm.valid) {
      this.commonService.register(this.subscribeForm.value)
        .subscribe({
          next: (registered) => {
            if(registered) {
              console.log('register message is ', JSON.stringify(registered));

              this.successAlert = true;
              this.errorAlert = false;
              this.successMsg = 'Subscribed Successfully'
              this.router.navigate(['/'])
            }
          },
          error: (error) => {
            if(error.status == 400){
              console.log('inside the error message if block ');

              this.errorAlert = true;
              this.successAlert = false;
              this.errorMsg = 'subscribe with this email already exists.'
              console.log(this.successMsg, this.successAlert);

            }
          }
        })
    }
  }

  search() {
    this.commonService.search(this.searchText, 'home', 'home')
    .subscribe({
      next: (result) => {
        this.homeData = result['home']?.results;
      }
    })
}


  toTop() {
    window.scrollTo(0, 0)
  }

}
