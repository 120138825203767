import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment.prod';
import { ExchangeRatesResponse } from '../exchange-rates.model';

@Injectable()
export class ExchangeRatesApiRequestService {
  private httpClient: HttpClient;
    constructor(private httpHandler: HttpBackend) {
      this.httpClient = new HttpClient(httpHandler);
    }

    public getExchangeRates(baseCurrency: string): Observable<ExchangeRatesResponse> {
        return this.httpClient.get<ExchangeRatesResponse>(`${environment.exchangeRatesAPIUrl}/latest?base=${baseCurrency}`);
    }
}
