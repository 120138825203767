import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceRoutingModule } from './service-routing.module';
import { ServiceComponent } from './service.component';
import { FlightsComponent } from './components/flights/flights.component';
import { PackagesComponent } from './components/packages/packages.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { VisaAssistanceComponent } from './components/visa-assistance/visa-assistance.component';
import { TransportationComponent } from './components/transportation/transportation.component';
import { HotelParkingComponent } from './components/hotel-parking/hotel-parking.component';
import { BookingScholorshipsComponent } from './components/booking-scholorships/booking-scholorships.component';
import { TranslatorsComponent } from './components/translators/translators.component';
import { AnnouncementsComponent } from '../opportunities/components/announcements/announcements.component';
import { CarRentalComponent } from './components/car-rental/car-rental.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotelBookingComponent } from './modals/hotel-booking/hotel-booking.component';
import { AirportTexisComponent } from './modals/airport-texis/airport-texis.component';
import { CarRentalsComponent } from './modals/car-rentals/car-rentals.component';
import { PaymentModalComponent } from './modals/payment-modal/payment-modal.component';
import { OnlinePaymentModalComponent } from './modals/online-payment-modal/online-payment-modal.component';
import { SocialComponent } from './modals/social/social.component';
import { FlightDetailsComponent } from './components/flights/flight-details/flight-details.component';
import { InsuranceDetailsComponent } from './components/insurance/insurance-details/insurance-details.component';
import { VisaAssistanceDetailsComponent } from './components/visa-assistance/visa-assistance-details/visa-assistance-details.component';
import { TransportationDetailsComponent } from './components/transportation/transportation-details/transportation-details.component';
import { CarRentalDetailsComponent } from './components/car-rental/car-rental-details/car-rental-details.component';
import { HotelParkingDetailsComponent } from './components/hotel-parking/hotel-parking-details/hotel-parking-details.component';
import { ScholorshipDetailsComponent } from './components/booking-scholorships/scholorship-details/scholorship-details.component';
import { TranslatorDetailsComponent } from './components/translator-details/translator-details.component';

@NgModule({
  declarations: [
    ServiceComponent,
    FlightsComponent,
    PackagesComponent,
    InsuranceComponent,
    VisaAssistanceComponent,
    TransportationComponent,
    HotelParkingComponent,
    BookingScholorshipsComponent,
    TranslatorsComponent,
    CarRentalComponent,
    HotelBookingComponent,
    AirportTexisComponent,
    CarRentalsComponent,
    PaymentModalComponent,
    OnlinePaymentModalComponent,
    SocialComponent,
    FlightDetailsComponent,
    InsuranceDetailsComponent,
    VisaAssistanceDetailsComponent,
    TransportationDetailsComponent,
    CarRentalDetailsComponent,
    HotelParkingDetailsComponent,
    ScholorshipDetailsComponent,
    TranslatorDetailsComponent,

  ],
  imports: [
    FormsModule,
    CommonModule,
    ServiceRoutingModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule




  ],
  exports: [BookingScholorshipsComponent, VisaAssistanceComponent, FlightsComponent]
})
export class ServiceModule { }
