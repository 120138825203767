import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],

})
export class CalendarComponent implements OnInit {

  public selected!: Date;
  @ViewChild('datetimepicker') dateTimePicker;
  constructor() { }

  ngOnInit(): void {
    this.selected = new Date(Date.now());
  }

}
