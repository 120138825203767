import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historic-places-details',
  templateUrl: './historic-places-details.component.html',
  styleUrls: ['./historic-places-details.component.css']
})
export class HistoricPlacesDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
